<template>
    <b-modal
      id="modal-add-contact"
      :title="getModalTitle()"
      ok-only
      :ok-title="i18nT(`Sauvegarder`)"
      no-close-on-backdrop
      @ok="saveNewContact"
      @hidden="resetForm"
      @shown="fetchData"
      size="lg"
    >
        <validation-observer ref="simpleRules">
            <b-form>
                <b-card no-body class="p-1">
                    <b-row>
                        <b-alert
                            class="ml-1"
                            variant="primary"
                            show
                            >
                            <div class="alert-body">
                                <span>
                                    <b-badge variant="primary">
                                      {{ i18nT(`Info` ) }}
                                    </b-badge>
                                     {{i18nT(`Créer un nouveau, ou choisir un déjà existant.`)}}
                                </span>
                            </div>
                        </b-alert>
                    </b-row>
                    <b-row>
                        <b-col cols="12"
                               md="6"
                        >
                            <b-form-group
                                :label="this.type == 'garant'? i18nT(`Garant(s)`) : i18nT(`Contacts`)"
                            >
                                <v-select
                                    v-model="availableContact"
                                    :options="availableContacts"
                                    label="text"
                                    :clearable="false"
                                    @input="updateContact(availableContact)"
                                    :selectable="selected => selected.disabled !== true"
                                />
                            </b-form-group>
                        </b-col>
                            <b-col cols="12"
                                   md="6"
                                 >
                                <b-form-group
                                    :label="i18nT(`Type`)"
                                    >
                                    <v-select
                                        v-model="newContact.ContactType"
                                        :options="contactTypes"
                                        label="text"
                                        :reduce="(item)=>item.value"
                                        :clearable="false"
                                        :placeholder="i18nT(`Choisir`)"
                                    />
                                </b-form-group>
                            </b-col>
                    </b-row>
                    <b-row>
                        <b-col
                            cols="12"
                            md="6"
                        >
                            <b-form-group
                                :label="i18nT(`Prénom`)"
                                class="required"
                            >
                                <validation-provider
                                    #default="{ errors }"
                                    :name="i18nT(`Prénom`)"
                                    rules="required"
                                >
                                    <b-form-input
                                        :placeholder="i18nT(`Prénom`)"
                                        v-model="newContact.ContactFirstName"
                                        :state="errors.length > 0 ? false:null"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                        <b-col
                            cols="12"
                            md="6"
                        >
                            <b-form-group
                                :label="i18nT(`Nom`)"
                                class="required"
                            >
                                <validation-provider
                                    #default="{ errors }"
                                    :name="i18nT(`Nom`)"
                                    rules="required"
                                >
                                    <b-form-input
                                        :placeholder="i18nT(`Nom`)"
                                        v-model="newContact.ContactLastName"
                                        :state="errors.length > 0 ? false:null"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                        <b-col
                            cols="6"
                            md="6"
                        >
                            <b-form-group
                                :label="i18nT(`E-Mail`)"
                            >
                                <validation-provider
                                    #default="{ errors }"
                                    name="contact-email"
                                    rules="email"
                                >
                                    <b-form-input
                                        :placeholder="i18nT(`email@domain.com`)"
                                        v-model="newContact.ContactEmail"
                                        id="contact-email"
                                        :state="errors.length > 0 ? false:null"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                        <b-col
                            cols="6"
                            md="6"
                        >
                            <b-form-group
                                :label="i18nT(`Mobile`)"
                            >
                                <validation-provider
                                    #default="{ errors }"
                                    name="contact-phone"
                                    :rules="{ validPhoneNumber: mazphoneObj }"
                                >
                                    <MazPhoneNumberInput
                                        :key="phoneReady"
                                        default-country-code="FR"
                                        :placeholder="i18nT(`Mobile`)"
                                        v-model="phone"
                                        @update="updatePhoneObj"
                                        id="Phone"
                                        :translations="{
                                        countrySelectorLabel: i18nT(`Country code`),
                                        countrySelectorError: i18nT(`Select a country`),
                                        phoneNumberLabel: i18nT(`Mobile phone number`),
                                        example: i18nT(`Example:`),
                                         }"
                                    />
                                    <small class="text-danger">{{ i18nT(errors[0]) }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12"
                               md="6"
                        >
                            <validation-provider
                                #default="{ errors }"
                                :name="i18nT(`Date de naissance`)"
                                rules="date_format"
                            >
                                <b-form-group
                                    :label="i18nT(`Date de naissance`)"
                                >
                                    <date-picker
                                        v-model="newContact.ContactBirthDate"
                                        reset-button
                                        :manual-input="true"
                                        :state="errors.length > 0 ? false : null"
                                    />
                                    <small class="text-danger">{{ i18nT(errors[0]) }}</small>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col
                            cols="12"
                            md="6"
                        >
                            <b-form-group
                                :label="i18nT(`Lieu de naissance`)"
                            >
                                <b-form-input
                                    v-model="newContact.ContactBirthPlace"
                                />
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col
                            cols="12"
                            md="12"
                            >
                            <b-row>
                                <b-col
                                    cols="12"
                                    md="12"
                                >
                                    <b-form-group
                                        :label="i18nT(`Adresse`)"
                                    >
                                        <b-form-input
                                            v-model="newContact.ContactAddress"
                                        />
                                    </b-form-group>
                                </b-col>
                                <b-col
                                    cols="12"
                                    md="6"
                                >
                                    <b-form-group
                                        :label="i18nT(`Code postal`)"
                                    >
                                        <b-form-input
                                            v-model="newContact.ContactZip"
                                        />
                                    </b-form-group>
                                </b-col>
                                <b-col
                                    cols="12"
                                    md="6"
                                >
                                    <b-form-group
                                        :label="i18nT(`Region`)"
                                    >
                                        <b-form-input
                                            v-model="newContact.ContactState" />
                                    </b-form-group>
                                </b-col>
                                <b-col
                                    cols="12"
                                    md="6"
                                >
                                    <b-form-group
                                        :label="i18nT(`Ville`)"
                                    >
                                        <b-form-input
                                            v-model="newContact.ContactCity" />
                                    </b-form-group>
                                </b-col>
                                <b-col
                                    cols="12"
                                    md="6"
                                >
                                    <b-form-group
                                        :label="i18nT(`Pays`)"
                                    >
                                        <v-select
                                            id="country"
                                            :options="countries"
                                            v-model="newContact.ContactCountry"
                                            label="text"
                                            :reduce="(e) => e.value"
                                            :clearable="false"
                                        />
                                    </b-form-group>
                                </b-col>
                              </b-row>
                          </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12">
                            <b-form-group :label="i18nT(`Commentaires`)">
                                <b-form-textarea v-model="newContact.ContactComments"
                                                 rows="3"
                                />
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-card>
            </b-form>
        </validation-observer>
    </b-modal>

</template>

<script>

import {
  BCol,
  BFormGroup,
  BForm,
  BFormInput,
  BModal,
  BCard,
  BRow,
} from 'bootstrap-vue'
import vSelect from "vue-select";
import {ValidationProvider, ValidationObserver, extend} from "vee-validate";
import { required, email} from '@validations';
import DatePicker from "@core/components/DatePicker.vue";
import moment from "moment";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { MazBtn,MazPhoneNumberInput } from 'maz-ui'



extend('validPhoneNumber', {
  params: ['phoneNumberData'],
  validate: (value, maz) => {
    if(maz.phoneNumberData){
      return maz.phoneNumberData.isValid ;
    }else {
      return false;
    }
  },
  message: `Invalid phone number`,
});
// TODO check dateFormat and follow on date formatting across the app
extend('date_format', {
  validate(value) {
    return {
      required: true,
      valid: moment(value, this.dateFormat).isValid() && value.length === 10 && moment(value).isBetween('1900-01-01', '2999-12-31')
    };
  },
  message: `Invalid date.`,
})

export default {
    components: {
      DatePicker,
      BFormInput,
      BRow,
      ValidationProvider,
      ValidationObserver,
      BCol,
      BForm,
      BCard,
      BFormGroup,
      vSelect,
      BModal,
      MazBtn,
      MazPhoneNumberInput
    },
    props: {
        contact: {
          type: Object,
          default: null
        },
        type: {
          type: String,
          default: null
        },
        location: {
            type: String,
            default: null
        }
    },
    data(){
        return{
            newContact: {
            },
            availableContacts: [],
            availableContact: {
              value: '-1',
              text: this.i18nT(`Choisir`),
            },
            contactTypes: [],
            contactType: {
              value: '-1',
              text: this.i18nT(`Choisir`),
            },
            countries: [],
            mazphoneObj: {},
            required,
            email,
            dateFormat: 'YYYY-MM-DD',
            phoneReady: 'no',
            isDataFetched: false,
            phone: '',
        }
    },
    methods:{
          fetchData(){
              if(this.isDataFetched) return;

              Promise.all([
                  this.$http.get(`system/constants/countries?`),
                  this.$http.get(`system/constants/userTypes`)
              ]).then(([countries, userTypes]) => {
                  this.countries = countries.data.data.map(country => {
                      return {
                          value: country.id,
                          text: country.label,
                      }
                  })
                  this.contactTypes = userTypes.data.data.map (type=>{
                      return {
                          value: type.id,
                          text: type.label
                      }
                  })

              }).then(() => {
                    this.getContacts();
              })
          },
          getContacts(){
              let url = this.type && !this.location ? `landlord/tenants/${this.type}s` : `landlord/${this.location}/contacts`;

              this.$http.get(url).then(({ data }) => {
                  if(this.type) {
                      this.availableContacts = data.data.map(contact => {
                          return {
                              value: contact.ContactID,
                              text: `${contact.ContactFirstName} ${contact.ContactLastName}`,
                              disabled: false,
                              contact,
                          }
                      })
                  }else{
                        this.availableContacts = data.data.contacts.map(contact => {
                            return {
                                value: contact.ContactID,
                                text: `${contact.ContactFirstName} ${contact.ContactLastName}`,
                                disabled: false,
                                contact,
                            }
                        })
                  }
                  this.isDataFetched = true;
              });
          },
          getModalTitle(){
              if(this.type == 'garant'){
                return this.i18nT(`Nouveau garant`);
              }
              return this.i18nT(`Nouveau contact`);
            },
          updateContact(val){
              this.phoneReady = 'no';
              this.newContact = val.contact;
              this.phone = '';
              this.$nextTick(() => {
                  this.phone = val.contact.ContactMobilePhone;
                  this.phoneReady = 'yes';
              });
              this.newContact.ContactBirthDate = val.contact.ContactBirthDate ? moment(val.contact.ContactBirthDate).format('YYYY-MM-DD') : null;

          },
          updatePhoneObj(dataCollected){
             this.mazphoneObj = dataCollected;
          },
          saveNewContact(event){
              event.preventDefault();
              //TODO needs to be adjusted when POST endpoint for new contact is ready
                  this.$refs.simpleRules.validate().then(success => {
                      if (success) {

                          const formData = new FormData();
                          if (this.newContact.ContactID) {
                              formData.append('ContactID', this.newContact.ContactID);
                          }
                          if (this.$route.params.id && !this.location) {
                              formData.append('tenant_id', this.$route.params.id);
                          } else if(this.$route.params.id && this.location){
                              switch(this.location){
                                  case 'properties':
                                        formData.append('property_id', this.$route.params.id);
                                        break;
                              }
                          }

                          formData.append('ContactFirstName', this.newContact.ContactFirstName);
                          formData.append('ContactLastName', this.newContact.ContactLastName);
                          formData.append('ContactEmail', this.newContact.ContactEmail);
                          formData.append('ContactMobilePhone', this.phone);
                          formData.append('ContactBirthDate', this.newContact.ContactBirthDate ? this.newContact.ContactBirthDate : null);
                          formData.append('ContactBirthPlace', this.newContact.ContactBirthPlace);
                          formData.append('ContactAddress', this.newContact.ContactAddress);
                          formData.append('ContactZip', this.newContact.ContactZip);
                          formData.append('ContactState', this.newContact.ContactState);
                          formData.append('ContactCity', this.newContact.ContactCity);
                          formData.append('ContactCountry', this.newContact.ContactCountry);
                          formData.append('ContactType', this.newContact.ContactType);
                          formData.append('ContactComments', this.newContact.ContactComments);


                          //TODO - change this to work with other views like leases etc.

                          const endpoint = this.location ? this.location : 'tenants';

                          if(this.type){
                                this.$http.post(`landlord/${endpoint}/${this.type}`, formData).then(({data}) => {
                                    this.$emit('add', data.data); // could be changed to refetchData later
                                    this.$toast({
                                        component: ToastificationContent,
                                        position: 'top-right',
                                        props: {
                                            title: this.successMessage(data),
                                            icon: 'InfoIcon',
                                            variant: 'success'
                                        }
                                    })
                                        this.$bvModal.hide('modal-add-contact');
                                        this.resetForm();
                                }).catch(ex => {
                                    this.$toast({
                                        component: ToastificationContent,
                                        position: 'top-right',
                                        props: {
                                            title: this.errorMessage(ex),
                                            icon: 'InfoIcon',
                                            variant: 'danger'
                                        }
                                    })
                                })
                          }
                      }
                  });
          },
          resetForm(){
              this.newContact = {};
              this.availableContact = {
                value: '-1',
                text: this.i18nT(`Choisir`),
              };
          },
    },
    watch: {
         contact: {
              handler: function (newVal) {
                  console.log(newVal.ContactBirthDate);

                  if(newVal){

                    this.newContact = {...newVal};
                    this.phone= this.newContact.ContactMobilePhone;
                    this.newContact.ContactBirthDate = newVal.ContactBirthDate;
                    console.log(this.newContact.ContactBirthDate);
                    this.phoneReady = 'yes';

                  }
              },
              deep: true
        },
        type(val){
            if(val){
                this.getContacts(val);
            }
        }
    },
}


</script>

<style lang="scss">

@import 'maz-ui/lib/css/maz-phone-number-input';


</style>