<template>
    <b-modal
        id="modal-edit-document"
        :title="getModalTitle"
        ok-only
        :ok-title="i18nT(`Sauvegarder`)"
        no-close-on-backdrop
        @ok="saveNewDocument"
        @hidden="resetForm"
        size="lg"
        @shown="fetchData"
        >
        <validation-observer ref="simpleRules" >
            <b-form>
                <b-card no-body class="p-1 mb-0">
                <b-row class="border-bottom">
                    <b-col
                        cols="12"
                        md="3"
                        >
                        <h4 class="mb-2 mt-2">
                          {{i18nT(`Information`)}}
                        </h4>
                    </b-col>
                </b-row>
                <b-row >
                    <b-col cols="12"
                           md="6"
                           class="pt-1 pl-2"
                        >
                        <b-form-group
                            :label="i18nT(`Type`)"
                            class="required"
                            >
                            <validation-provider
                                #default="{ errors }"
                                :name="i18nT(`Type`)"
                                rules="required"
                            >
                                <v-select
                                    v-model="documentData.DocumentTypeID"
                                    :options="documentTypes"
                                    label="text"
                                    :clearable="false"
                                    :reduce="item=>item.value"
                                    :state="errors.length > 0 ? false:null"
                                    :placeholder="i18nT(`Choisir`)"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                    <b-col
                        cols="12"
                        md="6"
                        class="pt-1 "
                    >
                        <b-form-group
                            :label="i18nT(`Immeuble`)"
                        >
                            <v-select
                                v-model="building"
                                :options="buildings"
                                label="text"
                                :clearable="false"
                                :placeholder="i18nT(`Choisir`)"

                            />
                        </b-form-group>
                    </b-col>
                </b-row>
<!--                <b-row>-->
<!--                    <b-col-->
<!--                        cols="12"-->
<!--                        md="6"-->
<!--                        class="pl-2"-->
<!--                        >-->
<!--                        <b-form-group-->
<!--                            :label="i18nT(`Immeuble`)"-->
<!--                            class="required"-->
<!--                             >-->
<!--                            <v-select-->
<!--                                v-model="building"-->
<!--                                :options="buildings"-->
<!--                                label="text"-->
<!--                                :clearable="false"-->
<!--                                :placeholder="i18nT(`Choisir`)"-->

<!--                            />-->
<!--                        </b-form-group>-->
<!--                    </b-col>-->
<!--                </b-row>-->
                <b-row>
                    <b-col
                        cols="12"
                        md="6"
                        class="pl-2"
                    >
                        <b-form-group
                            :label="i18nT(`Bien`)"
                        >
                            <v-select
                                v-model="chosenProperties"
                                :options="properties"
                                label="text"
                                multiple
                                :clearble="false"
                                :placeholder="i18nT(`Choisir`)"

                            />
                        </b-form-group>
                    </b-col>
                    <b-col
                        cols="12"
                        md="6"
                    >
                        <b-row>
                            <b-col cols="12" md="12">
                                <b-form-group
                                    :label="i18nT(`Location`)"
                                >
                                    <b-form-input
                                        v-model="location" />
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row v-if="!location">
                            <b-col cols="12">
                                <b-alert
                                    variant="primary"
                                    show
                                    size="sm"

                                >
                                    <div class="alert-body">
                                    <span>
                                        <b-badge variant="primary">
                                          {{ i18nT(`Info`) }}
                                        </b-badge>
                                         <small class="ml-1">{{ i18nT(`Pas lié à une location ou une réservation`) }}</small>
                                    </span>
                                    </div>
                                </b-alert>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
<!--                <b-row>-->
<!--                    <b-col-->
<!--                        cols="12"-->
<!--                        md="12"-->
<!--                        class="pl-2"-->
<!--                    >-->
<!--                        <b-row>-->
<!--                            <b-col cols="12" md="6">-->
<!--                            <b-form-group-->
<!--                            :label="i18nT(`Location`)"-->
<!--                            >-->
<!--                            <b-form-input-->
<!--                                v-model="location" />-->
<!--                            </b-form-group>-->
<!--                            </b-col>-->
<!--                        </b-row>-->
<!--                        <b-row v-if="!location">-->
<!--                            <b-alert-->
<!--                                class="ml-1"-->
<!--                                variant="primary"-->
<!--                                show-->

<!--                            >-->
<!--                                <div class="alert-body">-->
<!--                                    <span>-->
<!--                                        <b-badge variant="primary">-->
<!--                                          {{ i18nT(`Info` ) }}-->
<!--                                        </b-badge>-->
<!--                                         {{i18nT(`Pas lié à une location ou une réservation`)}}-->
<!--                                    </span>-->
<!--                                </div>-->
<!--                            </b-alert>-->
<!--                        </b-row>-->
<!--                    </b-col>-->
<!--                </b-row>-->
                <b-row>
                    <b-col cols="12"
                           md="12"
                           class="pl-2"
                        >
                        <b-form-group
                            :label="i18nT(`Description`)"
                            >
                            <b-form-textarea
                                id='Document description'
                                v-model="documentData.DocumentDescription"
                            />
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row v-if="document" class="pt-1">
                    <b-col
                        cols="12"
                        md="12"
                        class="pl-2"
                        >
                        <document-card :document="document"></document-card>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="12" md="8">
                        <div class='pb-1 pl-1'>
                          <!--                      TODO add methods for file upload-->
                            <b-button
                                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                class="mt-0 mt-1 mr-1 mb-0"
                                variant="outline-primary"
                                @click="onFilePick"
                              >
                              <feather-icon icon="PlusCircleIcon"/>
                              {{ i18nT(`Parcourir`) }}
                            </b-button>
                            <b-form-file
                                ref="new-document"
                                v-model="newDocument"
                                class="hidden"
                                />
                            <br />
                            <small class="mb-1">
                              {{ i18nT(`Formats acceptés: Word, Excel, PDF, Images (GIF, JPG, PNG)`)}}<br/>
                              {{i18nT(`Taille maximale: 15MB.`)}}
                            </small>
                        </div >
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols='12'
                           md="4"
                           class="pl-2"
                    >
                        <b-form-group
                            :label="i18nT(`Date`)"
                        >
                            <date-picker
                                v-model="documentData.DocumentCreateTime"
                                reset-button
                            />
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row class="border-bottom">
                    <b-col
                        cols="12"
                        md="3"
                        >
                        <h4 class="mt-2">
                          {{i18nT(`Partage`)}}
                        </h4>
                    </b-col>
                </b-row>
                <b-row class="pt-1 pl-1">
                    <b-col
                        cols="12"
                        md="12"
                        >
                        <b-form-checkbox
                            name="check-button"
                            switch
                            v-model="shareNewDocument"
                            >
                          {{i18nT(`Partager le document avec votre locataire`)}}
                        </b-form-checkbox>
                    </b-col>
                </b-row>
            </b-card>
            </b-form>
        </validation-observer>
    </b-modal>
</template>


<script>

import {
  BCard,
  BCardBody,
  BRow,
  BCol,
  BFormCheckbox,
  BFormGroup,
  BFormTextarea,
  BModal,
  BFormInput
} from "bootstrap-vue";
import vSelect from "vue-select";
import {ValidationProvider, ValidationObserver} from "vee-validate";
import { required } from '@validations';
import Ripple from "vue-ripple-directive";
import DatePicker from "@core/components/DatePicker.vue";
import {dictToSelectArray} from "@core/utils/utils";
import DocumentCard from "@/views/common/Documents/DocumentCard.vue";
import moment from "moment";


export default {
    components: {
      DocumentCard,
      DatePicker,
      BFormInput,
      BModal,
      BCard,
      BCardBody,
      BRow,
      BCol,
      BFormCheckbox,
      BFormGroup,
      BFormTextarea,
      vSelect,
      ValidationProvider,
      ValidationObserver
    },
    directives: {
        Ripple

    },
    props: {
      document: {
        type: Object,
        default: null
      }

    },
    data() {
        return {
          required,
          documentData: {},
          documentType: {text: this.i18nT(`Choisir`), value: '-1'},
          documentTypes: [],
          building: {text: this.i18nT(`Choisir`), value: '-1'},
          buildings: [],
          chosenProperties: [],
          properties: [],
          location: '',
          newDocumentDescription: '',
          newDocument: null,
          newDocumentName: '',
          newDocumentURL: '',
          newDocumentDate: '',
          shareNewDocument: false,
          isDataFetched: false,
        }
    },
    created() {


    },
    computed: {
        getModalTitle() {
            if(this.document) {
              return this.i18nT('Modifier le document')
            } else {
              return this.i18nT('Nouveau document')
            }
        },
    },
    methods: {
        fetchData(){
            if(this.isDataFetched) return;

            Promise.all([
                this.$http.get(`landlord/buildings/list`),
                this.$http.get(`landlord/properties/list`),
                this.$http.get(`system/constants/documentTypes`)
            ]).then(([buildings, properties, documentTypes]) => {
                this.buildings = buildings.data.data.map(building=>{
                    return {
                        value: building.id,
                        text: building.label
                    }
                })
                this.buildings.unshift({
                    value: '-1',
                    text: this.i18nT('Choisir')
                });

                this.properties = properties.data.data.map(property=>{
                    return {
                        value: property.id,
                        text: property.label
                    }
                })
                this.properties.unshift({
                    value: '-1',
                    text: this.i18nT('Choisir')
                });

                this.documentTypes = documentTypes.data.data.map(document=>{
                    return {
                        value: document.label.DocumentTypeID,
                        text: document.label.DocumentType
                    }
                })
                this.documentTypes.unshift({
                    value: '-1',
                    text: this.i18nT('Choisir')
                });

                if(this.document){
                    const foundDocumentType = this.documentTypes.find(type => type.value == this.document.DocumentTypeID)
                    if(foundDocumentType){
                        this.documentType = foundDocumentType
                    }

                    this.building = this.document.BuildingID == 0 ? '' : this.document.BuildingID;
                    const foundProperty = this.properties.find(property => property.value == this.document.PropertyID)
                    if(foundProperty){
                        this.chosenProperties.push(foundProperty)
                    }
                }
                this.isDataFetched = true;
            })

        },
        onFilePick(){
          this.$refs['new-document'].$refs.input.click()
          //TODO
        },
        onFileDelete(){
          this.newDocument = null;
          this.newDocumentName = '';
          this.newDocumentURL = '';

        },
        saveNewDocument(){
          //TODO
        },
        resetForm(){
          this.chosenProperties = []
          this.location = ''
          this.newDocumentDescription = ''
          this.newDocument = null
          this.newDocumentName = ''
          this.newDocumentURL = ''
          this.newDocumentDate = ''
          this.shareNewDocument = false
        }
    },
    watch: {
      document: {
        handler: function (val) {
          if(val) {
            this.documentData = {...val}
            const foundDocumentType = this.documentTypes.find(type => type.value == val.DocumentTypeID)
              if(foundDocumentType){
                this.documentType = foundDocumentType
              }

            this.building = val.BuildingID == 0 ? '' : val.BuildingID;
            const foundProperty = this.properties.find(property => property.value == val.PropertyID)
            if(foundProperty){
              this.chosenProperties.push(foundProperty)
            }
            // this.location = val.Location
            this.newDocumentDescription = val.DocumentDescription
            this.newDocumentName = val.DocumentName
            this.newDocumentDate = val.DocumentCreateTime
          }
        },
        deep: true
      },
      newDocument: function(val) {
        if (val) {
          this.newDocumentName = val.name
          this.newDocument = val
          this.newDocumentURL = URL.createObjectURL(val)
        } else{
          this.newDocumentName = ''
          this.newDocument = null
          this.newDocumentURL = ''
        }
      }
    }
}
</script>
