<template>
  <div>
<!--      action button top-right coner-->
        <ellipsis-dropdown 
            componentName="tenants"
            :showDeleteBtn="currentTenantId !== undefined"
            @save="validationForm"
            @delete="onDelete"
        />
    <validation-observer ref="simpleRules">
        <b-tabs
            v-model="tabIndex"
            vertical
            content-class="col-12 col-md-9 mt-1 mt-md-0"
            pills
            nav-wrapper-class="col-md-3 col-12"
            nav-class="nav-left"
            no-nav-style
        >
                   <!-- GENERAL INFORMATION -->

            <b-tab
                @click="changeTab('general-info')"
                    >
                    <template #title>
                        <div class="d-flex align-items-center mb-1 mr-1 ren-title text-truncate">
                            <div class="ren-title-img">
                                <RtIcon :size="21" variant="light" icon="circle-info"/>
                            </div>
                            <div>
                            <p class="m-0">
                                <strong>{{ i18nT(`Informations générales`)}}</strong>
                            </p>
                            <!-- <p class="m-0 text-secondary">
                                {{ i18nT(`Type, noms, addresse`)}}
                            </p> -->
                            </div>
                        </div>
                    </template>
                    <b-card>
                        <b-card-header class="p-0">
                            <h2>{{i18nT(`Informations générales`)}}</h2>
                        </b-card-header>
                        <!--                  TYPE-->
                        <b-row class="border-bottom">
                            <h4 class="mt-2 ml-1 mb-1">{{ i18nT(`Type`) }}</h4>
                        </b-row>
                        <b-row class=" mr-1 ml-1">
                            <b-col
                                cols="12"
                                md="12"
                            >
                                <b-row>
                                    <b-col
                                        cols="12"
                                        sm="12"
                                        class="pt-1"

                                    >
                                        <b-form-radio-group
                                            v-model="tenantType"
                                            name="tenantType"
                                            class="mt-1 pr-1 ren-radio-type-buttons d-flex flex-column flex-md-row"
                                        >
                                                <b-card no-body
                                                        class="ren-tenant-type d-flex mr-2 cursor-pointer"
                                                        :class="{'ren-type-border': tenantType =='person'}"
                                                        @click.prevent="changeType('person')"

                                                >
                                                    <b-card-body
                                                        class="d-flex"
                                                    >
                                                        <b-form-radio value="person"/>
                                                        <div class="flex-grow-1">
                                                            <div class='d-flex justify-content-between'>
                                                                <h6>{{ i18nT(`Particulier`) }}</h6>
                                                                <span class="text-muted">
                                                                <rt-icon icon="user" variant='light' :size="21"
                                                                         class="mr-1"/>
                                                            </span>
                                                            </div>
                                                        </div>
                                                    </b-card-body>
                                                </b-card>
                                                <b-card no-body
                                                        class="ren-tenant-type d-flex mr-2 cursor-pointer"
                                                        :class="{'ren-type-border': tenantType =='company'}"
                                                        @click.prevent="changeType('company')"
                                                >
                                                    <b-card-body class="d-flex">
                                                        <b-form-radio value="company"/>
                                                        <div class="flex-grow-1">
                                                            <div class='d-flex justify-content-between'>
                                                                <h6>{{ i18nT(`Société`) }}</h6>
                                                                <div class="text-muted">
                                                                    <rt-icon icon="briefcase" variant='light' :size="21"
                                                                             class="mr-1"/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </b-card-body>
                                                </b-card>
                                        </b-form-radio-group>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                      <!--              PHOTO AND COLOR-->
                      <b-row class="border-bottom">
                          <h4 class="mt-2 ml-1 mb-1">{{ i18nT(`Photo et couleur`) }}</h4>
                      </b-row>
                      <b-row class=" mr-1 ml-1 mb-2 mt-2 ">
                        <b-col  cols='12' md="12">
                          <b-row>
                            <b-col cols="12" md="6">
                              <avatar-control
                                  :profileData="tenantData"
                                  :parent-id="tenantData.TenantID"
                                  @setNewAvatar="onSetNewAvatar"
                                  userType="tenant"
                              />
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col cols="12" md="2" class="mb-1">
                              <b-form-group
                                  :label="i18nT(`Couleur`)"
                                  class="pl-0"
                              >
                                <b-form-input
                                    min="0"
                                    type="color"
                                    v-model="tenantColor"
                                    :placeholder="i18nT(`E.g. #C1B2A3`)"/>
                              </b-form-group>
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>

                        <!--              PERSONAL DETAILS-->

                        <div v-if="tenantType =='person' || !tenantType">
                            <b-row class="border-bottom">
                                <h4 class="mt-2 ml-1 mb-1">{{ i18nT(`Informations personnelles`) }}</h4>
                            </b-row>

                          <!--              PERSONAL DETAILS-->

                        <b-row class=" mr-1 ml-1 mb-2">
                            <b-col cols='12' md="12" class="my-2">
                                <b-row>
                                    <b-col
                                        cols="12" md="6">
                                        <h6 class="mb-1">
                                          {{i18nT(`Civilité`)}}
                                        </h6>
                                        <v-select
                                            v-model="tenantTitle"
                                            :options="titleOptions"
                                            label="text"
                                            :clearable="false"
                                            :reduce="(e) => e.value"
                                            class="mb-1"
                                        />
                                    </b-col>
                                </b-row>
                                  <b-row>
                                    <b-col
                                        cols="12"
                                        md="6"
                                    >
                                        <b-form-group
                                            :label="i18nT(`Prénom`)"
                                            class="required"
                                        >
                                            <validation-provider
                                                #default="{ errors }"
                                                :name="i18nT(`Prénom`)"
                                                rules="required"
                                            >
                                                <b-form-input
                                                    id="First name"
                                                    v-model="tenantData.TenantFirstName"
                                                    :state="errors.length > 0 ? false:null"
                                                />
                                                <small class="text-danger">{{ errors[0] }}</small>
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>
                                  </b-row>
                                  <b-row>  
                                        <b-col
                                            cols="12"
                                            md="6"
                                        >
                                            <b-form-group
                                                :label="i18nT(`Deuxième prénom`)"
                                            >
                                                <b-form-input
                                                    id="Middle name"
                                                    v-model="tenantData.TenantMiddleName"
                                                />
                                            </b-form-group>
                                        </b-col>
                                  </b-row>
                                  <b-row>  
                                        <b-col
                                            cols="12"
                                            md="6"
                                        >
                                            <b-form-group
                                                :label="i18nT(`Nom`)"
                                                class="required"
                                            >
                                                <validation-provider
                                                    #default="{ errors }"
                                                    :name="i18nT(`Nom`)"
                                                    rules="required"
                                                >
                                                    <b-form-input
                                                        id="Last name"
                                                        v-model="tenantData.TenantLastName"
                                                        :state="errors.length > 0 ? false:null"
                                                    />
                                                    <small class="text-danger">{{ errors[0] }}</small>
                                                </validation-provider>
                                          </b-form-group>
                                        </b-col>
                                </b-row>
                                <b-row>
                                    <b-col
                                        cols="12"
                                        md="6"
                                    >
                <!--                      TODO check date format when implemented-->

                                        <validation-provider
                                            #default="{ errors }"
                                            :name="i18nT(`Date de naissance`)"
                                            rules="date_format"
                                        >
                                            <b-form-group
                                                :label="i18nT(`Date de naissance`)"
                                            >
                                                <date-picker
                                                    v-model="tenantData.TenantBirthDate"
                                                    reset-button
                                                    :manual-input="true"
                                                    :state="errors.length > 0 ? false : null"
                                                />
                                                <small class="text-danger">{{ i18nT(errors[0]) }}</small>
                                            </b-form-group>
                                        </validation-provider>
                                    </b-col>
                                </b-row>
                                <b-row>    
                                    <b-col
                                        cols="12"
                                        md="6"
                                    >
                                        <b-form-group
                                            :label="i18nT(`Lieu de naissance`)"
                                        >
                                            <b-form-input
                                                id="Lieu de naissance"
                                                v-model="tenantData.TenantBirthPlace"
                                            />
                                        </b-form-group>
                                    </b-col>
                                  </b-row>
                                  <b-row>  
                                    <b-col
                                        cols="12"
                                        md="6"
                                    >
                                        <b-form-group
                                            :label="i18nT(`Numéro spi`)"
                                        >
                                            <b-form-input
                                                id="Fiscal number"
                                                v-model="tenantData.TenantFiscalCode"
                                            />
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>

                        <!--                        JOB STATUS-->
                        <b-row class="border-bottom">
                            <h4 class="mt-2 ml-1 mb-1">{{ i18nT(`Situation professionnelle`) }}</h4>
                        </b-row>
                        <b-row class=" mr-1 ml-1 mb-2">
                            <b-col cold="12" md="12" class="my-2">
                              <b-row>
                                <b-col cols='12'
                                        md="6"
                                       class="mb-1"

                                >
                                    <b-form-group
                                        :label="i18nT(`Profession`)"
                                    >
                                        <b-form-input
                                            id="Profession"
                                            v-model="tenantData.TenantProfession"
                                        />
                                    </b-form-group>
                                </b-col>
                              </b-row>
                              <b-row>    
                                <b-col cols="12"
                                        md="6"
                                >
                                    <b-form-group
                                        :label="i18nT(`Revenus mensuels`)"
                                    >
                                        <b-form-input
                                            id="Monthly income"
                                            v-model="tenantData.TenantRevenus"
                                        />
                                    </b-form-group>
                                </b-col>
                              </b-row>
                            </b-col>
                        </b-row>

                        <!--                        PROOF OF IDENTITY-->
                        <b-row class="border-bottom">
                            <h4 class="mt-2 ml-1 mb-1">{{ i18nT(`Pièce d'identité`) }}</h4>
                        </b-row>

                        <b-row class=" mr-1 ml-1 mb-2">
                            <b-col cols="12" md="12" class="my-2">
                                <b-row>
                                    <b-col cols='12'
                                           md="6"

                                    >
                                        <b-form-group
                                            :label="i18nT(`Type`)"
                                        >
                                            <v-select
                                                v-model="identityType"
                                                :options="identityOptions"
                                                label="text"
                                                :clearable="false"
                                                :reduce="(e) => e.value"
                                            />
                                        </b-form-group>
                                    </b-col>
                                  </b-row>
                                  <b-row>      
                                    <b-col cols="12"
                                           md="6"
                                    >
                                        <b-form-group
                                            :label="i18nT(`Numéro`)"
                                        >
                                            <b-form-input
                                                id="Number"
                                                v-model="tenantData.TenantIDNumber"
                                            />
                                        </b-form-group>
                                    </b-col>
                                  </b-row>
                                  <b-row>  
                                    <b-col cols='12'
                                           md="6"

                                    >
                                        <b-form-group
                                            :label="i18nT(`Expiration`)"
                                        >
                                            <date-picker
                                                v-model="tenantData.TenantIDExpiry"
                                                reset-button
                                                :manual-input="true"
                                            />
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col cols="12" md="6">
                                    <div>
                                        <span class="pb-0 mb-0" v-if="identityDocument|| identityDocumentName">
                                        <b-link v-if="identityDocumentURL" :href="identityDocumentURL" target="_blank">
                                            <b-img
                                                :src="identityDocumentURL"
                                                fluid
                                                thumbnail
                                                alt="identity-document"
                                                class="mr-1 ren-identity-photo"
                                            />
                                        </b-link>
                                        <b-button
                                            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                            variant="outline-danger"
                                            size="sm"
                                            class="cursor-pointer"
                                            @click="onFileDelete('identity-document')"
                                        >
                                            <rt-icon icon="trash" variant="light" :size="16"/>
                                          </b-button>
                                          </span>
                                        <span class="pb-0 mb-0" v-if="identityDocumentURL">

                                            <span>
<!--                                                TODO - check for correct date on upload - might need to be added to the endpoint-->
                                                  {{i18nT(`Pièce d'identité chargée le`)}} {{identityDocumentUploadDate}}
                                            </span>

                                            <b-button
                                                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                                variant="outline-warning"
                                                size="sm"
                                                class="cursor-pointer mx-1"
                                                :href="identityDocumentURL"
                                                target="_blank"
                                                >
                                                <rt-icon icon="download" variant="light" :size="16"/>
                                            </b-button>

                                        <b-button
                                            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                            variant="outline-danger"
                                            size="sm"
                                            class="cursor-pointer"
                                            @click="onFileDelete('identity-document')"
                                        >
                                            <rt-icon icon="trash" variant="light" :size="16"/>
                                          </b-button>
                                          </span>
                                          <br />
                                      <!--                      TODO add methods for file upload-->
                                        <b-button
                                            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                            class="mt-0 mt-1 mr-1 mb-0"
                                            variant="outline-primary"
                                            @click="onFilePick('identity-document')"
                                        >
                                            <rt-icon icon="plus-circle" variant="light" :size="14"/>
                                          {{ i18nT(`Parcourir`) }}
                                        </b-button>

                                        <b-form-file
                                            ref="identity-document"
                                            v-model="identityDocument"
                                            class="hidden"
                                        />

                                      </div >
                                      <small class="mb-1">{{i18nT(`Copie de la pièce d'identité.`)}}{{ i18nT(` Formats acceptés: Word, PDF, Images (GIF, JPG, PNG).`)}}</small>
                                  </b-col>
                                </b-row>
                            </b-col>
                        </b-row>

                        <!--                        CONTACT INFO -->
                        <b-row class="border-bottom">
                            <h4 class="mt-2 ml-1 mb-1">{{ i18nT(`Information de contact`) }}</h4>
                        </b-row>
                        <b-row class=" mr-1 ml-1 mb-2">
                            <b-col cols="12" md="12" class="my-2" >
                                <b-row>
                                    <b-col
                                        cols="12"
                                        md="6"
                                    >
                                        <b-form-group
                                            :label="i18nT(`Email`)"
                                        >
                                            <validation-provider
                                                #default="{ errors }"
                                                :name="i18nT(`E-Mail`)"
                                                rules="email"
                                            >
                                                <b-form-input
                                                    id="Email"
                                                    v-model="tenantData.TenantEmail"
                                                    :state="errors.length > 0 ? false:null"
                                                    :disabled="tenantData.StatusCode == '4'"
                                                />
                                                  <small class="text-danger">{{ errors[0] }}</small>
                                            </validation-provider>
                                            <small v-if="tenantData.StatusCode == '4'" class="ml-50">
                                                {{i18nT(`Vous ne pouvez pas modifier l'adresse mail du locataire connecté. C'est lui seul qui peut le faire depuis son compte.`)}}
                                            </small>
                                        </b-form-group>
                                    </b-col>
                                  </b-row>
                                <!--                  TODO - hook up the checkbox with the endpoint-->
                                <b-row>
                                    <b-col
                                        cols="12"
                                        md="6"
                                    >
                                        <b-form-checkbox
                                            name="check-button"
                                            switch
                                            v-model="sendTenantInvite"
                                            v-if="tenantData.StatusCode != '3' && tenantData.StatusCode != '4'"
                                        >
                                            {{i18nT(`Invitez le locataire à Rentila (un seul e-mail par locataire).`)}}
                                        </b-form-checkbox>
                                        <b-badge
                                            v-if="tenantData.StatusCode == 4"
                                            variant="light-secondary"
                                            class="badge-pill"
                                            v-b-tooltip = "i18nT(`Le locataire a créé son compte`)"
                                        >
                                            {{tenantData.StatusText}}
                                        </b-badge>
                                        <b-badge
                                            v-if="tenantData.StatusCode == 3"
                                            variant="light-warning"
                                            class="badge-pill"
                                            v-b-tooltip = "i18nT(`Le locataire n'a pas encore accepté l'invitation`)"
                                        >
                                            {{i18nT(`Locataire déjà invité`)}}
                                        </b-badge>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col cols="12" md="6" class="p-0">
                                        <b-alert
                                            class="mt-1 ml-1"
                                            variant="primary"
                                            show
                                        >
                                            <div class="alert-body">
                                            <span>
                                                <b-badge variant="primary">
                                                  {{ i18nT(`Info` ) }}
                                                </b-badge>
                                                <!--                            {{i18nT(`If you want to invite your tenants to Rentila and give them access to the website, please enter their email address (only ONE email per tenant).`)}} <br />-->
                                                <!--                                         {{i18nT(`The tenants will only be able to access information regarding their tenancy, their receipts and they’ll be able to send you messages through the interface.`)}}-->
                                                 {{i18nT(`Le locataire aura accès uniquement aux informations liées à sa location, ses quittances de loyer et il pourra vous envoyer des messages via son interface.`)}}
                                            </span>
                                            </div>
                                        </b-alert>
                                    </b-col>
                                </b-row>
                                  <b-row>    
                                    <b-col
                                        cols="12"
                                        md="6"
                                    >
                                        <b-form-group
                                            :label="i18nT(`E-mail secondaire`)"
                                        >
                                            <validation-provider
                                                #default="{ errors }"
                                                :name="i18nT(`E-Mail secondaire`)"
                                                rules="email"
                                            >
                                              <b-form-input
                                                  id="Secondary email"
                                                  v-model="tenantData.TenantEmailSecond"
                                                  :state="errors.length > 0 ? false:null"
                                              />
                                                <small class="text-danger">{{ errors[0] }}</small>
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col
                                        cols="12"
                                        md="6"
                                    >
                                        <b-form-group
                                            :label="i18nT(`Mobile`)"
                                        >
                                            <validation-provider
                                                #default="{ errors }"
                                                :name="i18nT(`Mobile`)"
                                                :rules="{ validPhoneNumber: mazphoneObj.TenantMobilePhone }"
                                            >
                                                <MazPhoneNumberInput
                                                    :key="phoneReady"
                                                    id="Phone"
                                                    default-country-code="FR"
                                                    v-model="tenantData.TenantMobilePhone"
                                                    @update="updatePhoneObj($event, 'TenantMobilePhone')"
                                                    :translations="{
                                                    countrySelectorLabel: i18nT(`Code du pays`),
                                                    countrySelectorError: i18nT(`Choisissez un pays`),
                                                    phoneNumberLabel: i18nT(`Numéro de téléphone`),
                                                    example: i18nT(`Example:`),
                                                  }"
                                                />
                                                <small class="text-danger">{{ i18nT(errors[0]) }}</small>
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>
                                  </b-row>
                                  <b-row>   
                                    <b-col
                                        cols="12"
                                        md="6"
                                    >
                                        <b-form-group
                                            :label="i18nT(`Telephone`)"
                                        >
                                            <validation-provider
                                                #default="{ errors }"
                                                :name="i18nT(`Telephone`)"
                                                :rules="{ validPhoneNumber: mazphoneObj.TenantPhone }"
                                            >
                                                <MazPhoneNumberInput
                                                    :key="phoneReady"
                                                    id="Phone"
                                                    default-country-code="FR"
                                                    v-model="tenantData.TenantPhone"
                                                    @update="updatePhoneObj($event, 'TenantPhone')"
                                                    :translations="{
                                                    countrySelectorLabel: i18nT(`Code du pays`),
                                                    countrySelectorError: i18nT(`Choisissez un pays`),
                                                    phoneNumberLabel: i18nT(`Numéro de téléphone`),
                                                    example: i18nT(`Example:`),
                                                    }"
                                                />
                                                <small class="text-danger">{{ i18nT(errors[0]) }}</small>
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>

            <!--              ADDRESS-->
                        <b-row class="border-bottom">
                            <h4 class="mt-2 ml-1 mb-1">{{ i18nT(`Adresse`) }}</h4>
                        </b-row>
                        <b-row class="mr-1 ml-1 mb-2 border-bottom">
                            <b-col
                                cols="12"
                                md="12"
                                class="my-2"
                            >
                                <b-row>
                                    <b-col
                                        cols="12"
                                        md="6"
                                    >
                                        <b-form-group
                                            :label="i18nT(`Adresse`)"
                                        >
                                            <b-form-input
                                                v-model="tenantData.TenantAddress1" />
                                        </b-form-group>
                                    </b-col>
                                  </b-row>
                                  <b-row>   
                                    <b-col
                                        cols="12"
                                        md="6"
                                    >
                                        <b-form-group
                                            :label="i18nT(`Adresse 2`)"
                                        >
                                            <b-form-input
                                                v-model="tenantData.TenantAddress2" />
                                        </b-form-group>
                                    </b-col>
                                  </b-row>
                                  <b-row>  
                                    <b-col
                                        cols="12"
                                        md="6"
                                    >
                                        <b-form-group
                                            :label="i18nT(`Code postal`)"
                                        >
                                            <b-form-input
                                                v-model="tenantData.TenantZip" />
                                        </b-form-group>
                                    </b-col>
                                  </b-row>
                                  <b-row>  
                                    <b-col
                                        cols="12"
                                        md="6"
                                    >
                                        <b-form-group
                                            :label="i18nT(`Région`)"
                                        >
                                            <b-form-input
                                                v-model="tenantData.TenantState" />
                                        </b-form-group>
                                    </b-col>
                                  </b-row>
                                  <b-row>  
                                    <b-col
                                        cols="12"
                                        md="6"
                                    >
                                        <b-form-group
                                            :label="i18nT(`Ville`)"
                                        >
                                            <b-form-input
                                                v-model="tenantData.TenantCity" />
                                        </b-form-group>
                                    </b-col>
                                  </b-row>
                                  <b-row>  
                                    <b-col
                                        cols="12"
                                        md="6"

                                    >
                                        <b-form-group
                                            :label="i18nT(`Pays`)"
                                        >
                                            <v-select
                                                id="country"
                                                :options="countries"
                                                v-model="tenantData.TenantCountry"
                                                label="text"
                                                :reduce="(e) => e.value"
                                                :clearable="false"
                                            />
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                        </div>
                        <!--            COMPANY DETAILS-->
                        <div v-if="tenantType =='company'">
                        <b-row class="border-bottom">
                            <h4 class="mt-2 ml-1 mb-1">{{ i18nT(`Information société`) }}</h4>
                        </b-row>
                        <b-row class=" mr-1 ml-1 mb-2">
                          <b-col
                              cols="12"
                              md="12"
                              class="my-2"
                          >
                                <b-row>
                              <b-col
                                  cols="12"
                                  md="6"
                              >
                                <b-form-group
                                    :label="i18nT(`Société`)"
                                    class="required"
                                >
                                  <validation-provider
                                      #default="{ errors }"
                                      :name="i18nT(`Société`)"
                                      rules="required"
                                  >
                                    <b-form-input
                                        id="Company name"
                                        v-model="tenantData.TenantCompanyName"
                                        :state="errors.length > 0 ? false:null"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                  </validation-provider>

                                  <small>{{i18nT(`Si vous remplissez ce champs, le nom de la société figurera sur les documents.`)}}</small>
                                </b-form-group>

                              </b-col>
                            </b-row>
                                <b-row>
                                    <b-col cols="12"
                                           md="6"
                                    >
                                        <b-form-group
                                            :label="i18nT(`NO. TVA`)"
                                        >
                                            <b-form-input
                                                id="Company number"
                                                v-model="tenantData.TenantVatNumber"
                                            />
                                            <small>{{ i18nT(`Si vous remplissez ce champs, cette information figurera sur certains documents automatiques.`) }}</small>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col
                                        cols="12"
                                        md="6"
                                    >
                                        <b-form-group
                                            :label="i18nT(`RCS / SIREN`)"
                                        >
                                            <b-form-input
                                                id="Company registration number"
                                                v-model="tenantData.TenantSiret"
                                            />
                                        </b-form-group>
                                    </b-col>
                                </b-row>

                                <b-row>
                                    <b-col
                                        cols="12"
                                        md="6"
                                    >
                                        <b-form-group
                                            :label="i18nT(`Capital`)"
                                        >
                                            <b-form-input
                                                id="Company capital"
                                                v-model="tenantData.TenantCapital"
                                            />
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col
                                        cols="12"
                                        md="6"
                                    >
                                        <!--                    TODO: ask which property is registration number-->

                                        <b-form-group
                                            :label="i18nT(`Domaine d'activité`)"
                                        >
                                            <b-form-input
                                                id="Field of activity"
                                                v-model="tenantData.TenantCompanyDescription"
                                            />
                                        </b-form-group>
                                    </b-col>
                                </b-row>

                          </b-col>
                        </b-row>

                        <!--            PROFESSIONAL ADDRESS-->
                            <b-row class="border-bottom">
                                <h4 class="mt-2 ml-1 mb-1">{{ i18nT(`Adresse professionnelle`) }}</h4>
                            </b-row>
                        <b-row class="mr-1 ml-1 mb-2">
                          <b-col
                              cols="12"
                              md="12"
                              class="my-2"
                          >
                                <b-row>
                                    <b-col
                                        cols="12"
                                        md="6"
                                    >
                                        <b-form-group
                                            :label="i18nT(`Employeur`)"
                                        >
                                            <b-form-input
                                                id="Employer"
                                                v-model="tenantData.TenantProEmployer"
                                            />
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col
                                        cols="12"
                                        md="6"
                                    >
                                        <b-form-group
                                            :label="i18nT(`Telephone`)"
                                        >
                                            <validation-provider
                                                #default="{ errors }"
                                                :name="i18nT(`Telephone`)"
                                                :rules="{ validPhoneNumber: mazphoneObj.TenantProPhone }"
                                            >
                                                <MazPhoneNumberInput
                                                    :key="phoneReady"
                                                    id="Phone"
                                                    default-country-code="FR"
                                                    v-model="tenantData.TenantProPhone"
                                                    @update="updatePhoneObj($event, 'TenantProPhone')"
                                                    :translations="{
                                                countrySelectorLabel: i18nT(`Country code`),
                                                countrySelectorError: i18nT(`Select a country`),
                                                phoneNumberLabel: i18nT(`Phone number`),
                                                example: i18nT(`Example:`),
                                                }"
                                                />
                                                <small class="text-danger">{{ i18nT(errors[0]) }}</small>
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col
                                        cols="12"
                                        md="6"
                                    >
                                        <b-form-group
                                            :label="i18nT(`Adresse`)"
                                        >
                                            <b-form-input
                                                v-model="tenantData.TenantProAddress"/>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col
                                        cols="12"
                                        md="6"
                                    >
                                        <b-form-group
                                            :label="i18nT(`Code postal`)"
                                        >
                                            <b-form-input
                                                v-model="tenantData.TenantProZip"/>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col
                                        cols="12"
                                        md="6"
                                    >
                                        <b-form-group
                                            :label="i18nT(`Région`)"
                                        >
                                            <b-form-input
                                                v-model="tenantData.TenantProState"/>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col
                                        cols="12"
                                        md="6"
                                    >
                                        <b-form-group
                                            :label="i18nT(`Ville`)"
                                        >
                                            <b-form-input
                                                v-model="tenantData.TenantProCity"/>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col
                                        cols="12"
                                        md="6"
                                    >
                                        <b-form-group
                                            :label="i18nT(`Pays`)"
                                        >
                                            <v-select
                                                id="country"
                                                :options="countries"
                                                v-model="tenantData.TenantProCountry"
                                                label="text"
                                                :reduce="(e) => e.value"
                                                :clearable="false"
                                            />
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                          </b-col>
                        </b-row>

                        <!--            BANK DETAILS-->
                        <b-row class="border-bottom">
                            <h4 class="mt-2 ml-1 mb-1">{{ i18nT(`Coordonnées bancaires`) }}</h4>
                        </b-row>
                        <b-row class="mr-1 ml-1 mb-2">
                          <b-col
                              cols="12"
                              md="12"
                              class="my-2"
                          >

                              <b-row>
                                  <b-col
                                  cols="12"
                                  md="6"
                              >
                                <b-form-group
                                    :label="i18nT(`Banque`)"
                                >
                                  <b-form-input
                                      id="Profession"
                                      v-model="tenantData.TenantBankName"
                                  />
                                </b-form-group>
                              </b-col>
                              </b-row>
                              <b-row>
                                  <b-col
                                  cols="12"
                                  md="6"
                              >
                                <b-form-group
                                    :label="i18nT(`Adresse`)"
                                >
                                  <b-form-input
                                      v-model="tenantData.TenantBankAddress" />
                                </b-form-group>
                              </b-col>
                              </b-row>
                              <b-row>
                                  <b-col
                                  cols="12"
                                  md="6"
                              >
                                <b-form-group
                                    :label="i18nT(`Code postal`)"
                                >
                                  <b-form-input
                                      v-model="tenantData.TenantBankZip" />
                                </b-form-group>
                              </b-col>
                              </b-row>
                              <b-row>
                                  <b-col
                                  cols="12"
                                  md="6"
                              >
                                <b-form-group
                                    :label="i18nT(`Ville`)"
                                >
                                  <b-form-input
                                      v-model="tenantData.TenantBankCity" />
                                </b-form-group>
                              </b-col>
                              </b-row>
                              <b-row>
                                  <b-col
                                  cols="12"
                                  md="6"
                              >
                                <b-form-group
                                    :label="i18nT(`Pays`)"
                                >
                                  <v-select
                                      id="country"
                                      :options="countries"
                                      v-model="tenantData.TenantBankCountry"
                                      label="text"
                                      :reduce="(e) => e.value"
                                      :clearable="false"
                                  />
                                </b-form-group>
                              </b-col>
                              </b-row>
                              <b-row>
                                  <b-col
                                  cols="12"
                                  md="6"
                              >
                                <b-form-group
                                    :label="i18nT(`IBAN`)"
                                >
                                  <b-form-input
                                      id="Profession"
                                      v-model="tenantData.TenantBankIBAN"
                                  />
                                </b-form-group>
                              </b-col>
                              </b-row>
                              <b-row>
                                  <b-col
                                  cols="12"
                                  md="6"
                              >
                                <b-form-group
                                    :label="i18nT(`SWIFT/BIC`)"
                                >
                                  <b-form-input
                                      id="Profession"
                                      v-model="tenantData.TenantBankSwiftBic"
                                  />
                                </b-form-group>
                              </b-col>
                              </b-row>
                          </b-col>
                        </b-row>

                        <!--            ADDITIONAL INFO-->
                            <b-row class="border-bottom">
                                <h4 class="mt-2 ml-1 mb-1">{{ i18nT(`Informations complémentaires`) }}</h4>
                            </b-row>
                        <b-row class="mr-1 ml-1 mb-2 border-bottom">
                          <b-col
                              cols="12"
                              md="12"
                              class="my-2"
                          >
                            <b-row>
                              <b-col
                                  cols="12"
                                  md="6"
                              >
                                <b-form-group
                                    :label="i18nT(`Nouvelle Adresse`)"
                                >
                                  <b-form-input
                                      id="New address"
                                      v-model="tenantData.TenantLeaveAddress"
                                      :placeholder="i18nT(`Ex. 1 avenue de l'Opera, 75001 Paris, France`)"
                                  />
                                  <small>{{i18nT(`Nouvelle adresse du locataire pour toute future correspondance après son départ.`)}}</small>
                                </b-form-group>
                              </b-col>
                            </b-row>
                            <b-row>
                              <b-col cols="12" md="6">
                                <b-form-group
                                    :label="i18nT(`Note privée`)"
                                >
                                  <b-form-textarea
                                      :placeholder="i18nT(`Ex. Le locataire parfait qu'on rêve tous d'avoir...`)"
                                      if='Private note'
                                      v-model="tenantData.TenantNotes"
                                  />
                                  <small>{{i18nT(`Cette note est visible uniquement pour vous.`)}}</small>
                                </b-form-group>
                              </b-col>
                            </b-row>
                          </b-col>
                        </b-row>
                      </div>
                      <b-row>
                          <b-col cols="6" class="text-muted">
                              <div  class="m-1 pl-1">
                                  <div v-if="tenantData.TenantCreateTime">
                                  <span>
                                  <rt-icon icon="calendar-plus" variant="light"/>
                              </span>
                                      <small>
                                          {{ i18nT(`Créé le`) }} {{ tenantData.TenantCreateTime }}
                                      </small>
                                  </div>
                                  <div v-if="tenantData.TenantLastEditDate">
                                  <span>
                                  <rt-icon icon="calendar-pen" variant="light"/>
                              </span>
                                      <small>
                                          {{ i18nT(`Modifié le `) }} {{ tenantData.TenantLastEditDate }}
                                      </small>
                                  </div>
                              </div>
                          </b-col>
                          <b-col cols="6">
                            <div class="text-right m-1">
                                <b-button
                                    variant="outline-secondary"
                                    class="mb-1 mr-1"
                                    :to="{ name: 'tenants'}"
                                >
                                    {{i18nT(`Annuler`)}}
                                </b-button>
                            <b-button
                                variant="primary"
                                class="mb-1"
                                @click.prevent="validationForm"
                            >
                              {{i18nT(`Sauvegarder`)}}
                              </b-button>
                        </div>
                          </b-col>

                      </b-row>
                  </b-card>
            </b-tab>

                    <!--      ADDITIONAL INFO-->
            <b-tab
                :title="i18nT(`Informations complémentaires`)"
                @click="changeTab('additional-info')"
                >
                    <template #title>
                        <div class="d-flex align-items-center mb-1 mr-1 ren-title">
                            <div class="ren-title-img">
                                <RtIcon :size="21" variant="light" icon="circle-info"/>
                            </div>
                            <div>
                                <p class="m-0">
                                    <strong>{{ i18nT(`Informations complémentaires`)}}</strong>
                                </p>
                                <!-- <p class="m-0 p-0 text-secondary text-truncate">
                                    {{ i18nT(`Données professionnelles, banque etc.`)}}
                                </p> -->
                            </div>
                        </div>
                    </template>
                <b-card>
                    <b-card-header class="p-0">
                        <h2>{{i18nT(`Informations complémentaires`)}}</h2>
                    </b-card-header>

                      <!--            COMPANY INFORMATION-->
                    <div v-if="tenantType =='person' || !tenantType">
                        <b-row class="border-bottom">
                            <h4 class="mt-2 ml-1 mb-1">{{ i18nT(`Information société`) }}</h4>
                        </b-row>
                        <b-row class=" mr-1 ml-1 mb-2">
                            <b-col
                                cols="12"
                                md="12"
                                class="my-2"
                            >
                                <b-row>
                                    <b-col
                                        cols="12"
                                        md="6"
                                    >
                                        <b-form-group
                                            :label="i18nT(`Société`)"
                                        >
                                           <b-form-input
                                                    id="Company name"
                                                    v-model="tenantData.TenantCompanyName"
                                                />
                                        </b-form-group>

                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col cols="12"
                                           md="6"
                                    >
                                        <b-form-group
                                            :label="i18nT(`NO. TVA`)"
                                        >
                                            <b-form-input
                                                id="Company number"
                                                v-model="tenantData.TenantVatNumber"
                                            />
                                            <small>{{ i18nT(`Si vous remplissez ce champs, cette information figurera sur certains documents automatiques.`) }}</small>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col
                                        cols="12"
                                        md="6"
                                    >
                                        <b-form-group
                                            :label="i18nT(`RCS / SIREN`)"
                                        >
                                            <b-form-input
                                                id="Company registration number"
                                                v-model="tenantData.TenantSiret"
                                            />
                                        </b-form-group>
                                    </b-col>
                                </b-row>

                                <b-row>
                                    <b-col
                                        cols="12"
                                        md="6"
                                    >
                                        <b-form-group
                                            :label="i18nT(`Capital`)"
                                        >
                                            <b-form-input
                                                id="Company capital"
                                                v-model="tenantData.TenantCapital"
                                            />
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col
                                        cols="12"
                                        md="6"
                                    >
                                        <!--                    TODO: ask which property is registration number-->

                                        <b-form-group
                                            :label="i18nT(`Domaine d'activité`)"
                                        >
                                            <b-form-input
                                                id="Field of activity"
                                                v-model="tenantData.TenantCompanyDescription"
                                            />
                                        </b-form-group>
                                    </b-col>
                                </b-row>

                            </b-col>
                        </b-row>

                        <!--            PROFESSIONAL ADDRESS-->
                        <b-row class="border-bottom">
                            <h4 class="mt-2 ml-1 mb-1">{{ i18nT(`Adresse professionnelle`) }}</h4>
                        </b-row>
                        <b-row class="mr-1 ml-1 mb-2">
                            <b-col
                                cols="12"
                                md="12"
                                class="my-2"
                            >
                                <b-row>
                                    <b-col
                                        cols="12"
                                        md="6"
                                    >
                                        <b-form-group
                                            :label="i18nT(`Employeur`)"
                                        >
                                            <b-form-input
                                                id="Employer"
                                                v-model="tenantData.TenantProEmployer"
                                            />
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col
                                        cols="12"
                                        md="6"
                                    >
                                        <b-form-group
                                            :label="i18nT(`Telephone`)"
                                        >
                                            <validation-provider
                                                #default="{ errors }"
                                                :name="i18nT(`Telephone`)"
                                                :rules="{ validPhoneNumber: mazphoneObj.TenantProPhone1 }"
                                            >
                                                <MazPhoneNumberInput
                                                    :key="phoneReady"
                                                    id="Phone"
                                                    default-country-code="FR"
                                                    v-model="tenantData.TenantProPhone"
                                                    @update="updatePhoneObj($event, 'TenantProPhone1')"
                                                    :translations="{
                                                    countrySelectorLabel: i18nT(`Code du pays`),
                                                    countrySelectorError: i18nT(`Choisissez un pays`),
                                                    phoneNumberLabel: i18nT(`Numéro de téléphone`),
                                                example: i18nT(`Example:`),
                                                }"
                                                />
                                                <small class="text-danger">{{ i18nT(errors[0]) }}</small>
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col
                                        cols="12"
                                        md="6"
                                    >
                                        <b-form-group
                                            :label="i18nT(`Adresse`)"
                                        >
                                            <b-form-input
                                                v-model="tenantData.TenantProAddress"/>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col
                                        cols="12"
                                        md="6"
                                    >
                                        <b-form-group
                                            :label="i18nT(`Code postal`)"
                                        >
                                            <b-form-input
                                                v-model="tenantData.TenantProZip"/>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col
                                        cols="12"
                                        md="6"
                                    >
                                        <b-form-group
                                            :label="i18nT(`Région`)"
                                        >
                                            <b-form-input
                                                v-model="tenantData.TenantProState"/>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col
                                        cols="12"
                                        md="6"
                                    >
                                        <b-form-group
                                            :label="i18nT(`Ville`)"
                                        >
                                            <b-form-input
                                                v-model="tenantData.TenantProCity"/>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col
                                        cols="12"
                                        md="6"
                                    >
                                        <b-form-group
                                            :label="i18nT(`Pays`)"
                                        >
                                            <v-select
                                                id="country"
                                                :options="countries"
                                                v-model="tenantData.TenantProCountry"
                                                label="text"
                                                :reduce="(e) => e.value"
                                                :clearable="false"
                                            />
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>

                        <!--            BANK DETAILS-->
                        <b-row class="border-bottom">
                            <h4 class="mt-2 ml-1 mb-1">{{ i18nT(`Coordonnées bancaires`) }}</h4>
                        </b-row>
                        <b-row class="mr-1 ml-1 mb-2">
                            <b-col
                                cols="12"
                                md="12"
                                class="my-2"
                            >

                                <b-row>
                                    <b-col
                                        cols="12"
                                        md="6"
                                    >
                                        <b-form-group
                                            :label="i18nT(`Banque`)"
                                        >
                                            <b-form-input
                                                id="Profession"
                                                v-model="tenantData.TenantBankName"
                                            />
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col
                                        cols="12"
                                        md="6"
                                    >
                                        <b-form-group
                                            :label="i18nT(`Adresse`)"
                                        >
                                            <b-form-input
                                                v-model="tenantData.TenantBankAddress" />
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col
                                        cols="12"
                                        md="6"
                                    >
                                        <b-form-group
                                            :label="i18nT(`Code postal`)"
                                        >
                                            <b-form-input
                                                v-model="tenantData.TenantBankZip" />
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col
                                        cols="12"
                                        md="6"
                                    >
                                        <b-form-group
                                            :label="i18nT(`Ville`)"
                                        >
                                            <b-form-input
                                                v-model="tenantData.TenantBankCity" />
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col
                                        cols="12"
                                        md="6"
                                    >
                                        <b-form-group
                                            :label="i18nT(`Pays`)"
                                        >
                                            <v-select
                                                id="country"
                                                :options="countries"
                                                v-model="tenantData.TenantBankCountry"
                                                label="text"
                                                :reduce="(e) => e.value"
                                                :clearable="false"
                                            />
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col
                                        cols="12"
                                        md="6"
                                    >
                                        <b-form-group
                                            :label="i18nT(`IBAN`)"
                                        >
                                            <b-form-input
                                                id="Profession"
                                                v-model="tenantData.TenantBankIBAN"
                                            />
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col
                                        cols="12"
                                        md="6"
                                    >
                                        <b-form-group
                                            :label="i18nT(`SWIFT/BIC`)"
                                        >
                                            <b-form-input
                                                id="Profession"
                                                v-model="tenantData.TenantBankSwiftBic"
                                            />
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>

                        <!--            ADDITIONAL INFO-->
                        <b-row class="border-bottom">
                            <h4 class="mt-2 ml-1 mb-1">{{ i18nT(`Informations complémentaires`) }}</h4>
                        </b-row>
                        <b-row class="mr-1 ml-1 mb-2 border-bottom">
                            <b-col
                                cols="12"
                                md="12"
                                class="my-2"
                            >
                                <b-row>
                                    <b-col
                                        cols="12"
                                        md="6"
                                    >
                                        <b-form-group
                                            :label="i18nT(`Nouvelle Adresse`)"
                                        >
                                            <b-form-input
                                                id="New address"
                                                v-model="tenantData.TenantLeaveAddress"
                                                :placeholder="i18nT(`Ex. 1 avenue de l'Opera, 75001 Paris, France`)"
                                            />
                                            <small>{{i18nT(`Nouvelle adresse du locataire pour toute future correspondance après son départ.`)}}</small>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col cols="12" md="6">
                                        <b-form-group
                                            :label="i18nT(`Note privée`)"
                                        >
                                            <b-form-textarea
                                                :placeholder="i18nT(`Ex. Le locataire parfait qu'on rêve tous d'avoir...`)"
                                                if='Private note'
                                                v-model="tenantData.TenantNotes"
                                            />
                                            <small>{{i18nT(`Cette note est visible uniquement pour vous.`)}}</small>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                    </div>
                    <div v-if="tenantType =='company'">

                        <!--              PERSONAL DETAILS-->
                        <b-row class="border-bottom">
                            <h4 class="mt-2 ml-1 mb-1">{{ i18nT(`Informations personnelles`) }}</h4>
                        </b-row>
                        <b-row class=" mr-1 ml-1 mb-2">
                            <b-col cols='12' md="12" class="my-2">
                                <b-row>
                                    <b-col
                                        cols="12" md="6">
                                        <h6 class="mb-1">
                                            {{i18nT(`Civilité`)}}
                                        </h6>
                                        <v-select
                                            v-model="tenantTitle"
                                            :options="titleOptions"
                                            label="text"
                                            :clearable="false"
                                            :reduce="(e) => e.value"
                                            class="mb-1"
                                        />
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col
                                        cols="12"
                                        md="6"
                                    >
                                        <b-form-group
                                            :label="i18nT(`Prénom`)"
                                        >
                                                <b-form-input
                                                    id="First name"
                                                    v-model="tenantData.TenantFirstName"
                                                />
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col
                                        cols="12"
                                        md="6"
                                    >
                                        <b-form-group
                                            :label="i18nT(`Deuxième prénom`)"
                                        >
                                            <b-form-input
                                                id="Middle name"
                                                v-model="tenantData.TenantMiddleName"
                                            />
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col
                                        cols="12"
                                        md="6"
                                    >
                                        <b-form-group
                                            :label="i18nT(`Nom`)"
                                        >
                                                <b-form-input
                                                    id="Last name"
                                                    v-model="tenantData.TenantLastName"
                                                />
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col
                                        cols="12"
                                        md="6"
                                    >
                                        <!--                      TODO check date format when implemented-->

                                        <validation-provider
                                            #default="{ errors }"
                                            :name="i18nT(`Date de naissance`)"
                                            rules="date_format"
                                        >
                                            <b-form-group
                                                :label="i18nT(`Date de naissance`)"
                                            >
                                                <date-picker
                                                    v-model="tenantData.TenantBirthDate"
                                                    reset-button
                                                    :manual-input="true"
                                                    :state="errors.length > 0 ? false : null"
                                                />
                                                <small class="text-danger">{{ i18nT(errors[0]) }}</small>
                                            </b-form-group>
                                        </validation-provider>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col
                                        cols="12"
                                        md="6"
                                    >
                                        <b-form-group
                                            :label="i18nT(`Lieu de naissance`)"
                                        >
                                            <b-form-input
                                                id="Lieu de naissance"
                                                v-model="tenantData.TenantBirthPlace"
                                            />
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col
                                        cols="12"
                                        md="6"
                                    >
                                        <b-form-group
                                            :label="i18nT(`Numéro spi`)"
                                        >
                                            <b-form-input
                                                id="Fiscal number"
                                                v-model="tenantData.TenantFiscalCode"
                                            />
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>

                        <!--                        JOB STATUS-->
                        <b-row class="border-bottom">
                            <h4 class="mt-2 ml-1 mb-1">{{ i18nT(`Situation professionnelle`) }}</h4>
                        </b-row>
                        <b-row class=" mr-1 ml-1 mb-2">
                            <b-col cold="12" md="12" class="my-2">
                                <b-row>
                                    <b-col cols='12'
                                           md="6"
                                           class="mb-1"

                                    >
                                        <b-form-group
                                            :label="i18nT(`Profession`)"
                                        >
                                            <b-form-input
                                                id="Profession"
                                                v-model="tenantData.TenantProfession"
                                            />
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col cols="12"
                                           md="6"
                                    >
                                        <b-form-group
                                            :label="i18nT(`Revenus mensuels`)"
                                        >
                                            <b-form-input
                                                id="Monthly income"
                                                v-model="tenantData.TenantRevenus"
                                            />
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>

                        <!--                        PROOF OF IDENTITY-->
                        <b-row class="border-bottom">
                            <h4 class="mt-2 ml-1 mb-1">{{ i18nT(`Pièce d'identité`) }}</h4>
                        </b-row>
                        <b-row class=" mr-1 ml-1 mb-2">
                            <b-col cols="12" md="12" class="my-2">
                                <b-row>
                                    <b-col cols='12'
                                           md="6"

                                    >
                                        <b-form-group
                                            :label="i18nT(`Type`)"
                                        >
                                            <v-select
                                                v-model="identityType"
                                                :options="identityOptions"
                                                label="text"
                                                :clearable="false"
                                                :reduce="(e) => e.value"
                                            />
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col cols="12"
                                           md="6"
                                    >
                                        <b-form-group
                                            :label="i18nT(`Numéro`)"
                                        >
                                            <b-form-input
                                                id="Number"
                                                v-model="tenantData.TenantIDNumber"
                                            />
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col cols='12'
                                           md="6"

                                    >
                                        <b-form-group
                                            :label="i18nT(`Expiration`)"
                                        >
                                            <date-picker
                                                v-model="tenantData.TenantIDExpiry"
                                                reset-button
                                                :manual-input="true"
                                            />
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col cols="12" md="6">
                                        <div>
                                        <span class="pb-0 mb-0" v-if="identityDocument|| identityDocumentName">
                                        <b-link v-if="identityDocumentURL" :href="identityDocumentURL" target="_blank">
                                            <b-img
                                                :src="identityDocumentURL"
                                                fluid
                                                thumbnail
                                                alt="identity-document"
                                                class="mr-1 ren-identity-photo"
                                            />
                                        </b-link>
                                        <b-button
                                            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                            variant="outline-danger"
                                            size="sm"
                                            class="cursor-pointer"
                                            @click="onFileDelete('identity-document')"
                                        >
                                            <rt-icon icon="trash" variant="light" :size="16"/>
                                          </b-button>
                                          </span>
                                            <span class="pb-0 mb-0" v-if="tenantData['id_card_download_url']">

                                            <span>
<!--                                                TODO - check for correct date on upload - might need to be added to the endpoint-->
                                                  {{i18nT(`Pièce d'identité chargée le`)}}</span> {{ identityDocumentUploadDate | date }}


                                            <b-button
                                                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                                variant="outline-warning"
                                                size="sm"
                                                class="cursor-pointer mx-1"
                                                :href="identityDocumentURL"
                                                target="_blank"
                                            >
                                              <rt-icon icon="download" variant="light" :size="16"/>
                                            </b-button>

                                        <b-button
                                            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                            variant="outline-danger"
                                            size="sm"
                                            class="cursor-pointer"
                                            @click="onFileDelete('identity-document')"
                                        >
                                        <rt-icon icon="trash" variant="light" :size="16"/>
                                          </b-button>
                                          </span>
                                            <br />
                                            <!--                      TODO add methods for file upload-->
                                            <b-button
                                                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                                class="mt-0 mt-1 mr-1 mb-0"
                                                variant="outline-primary"
                                                @click="onFilePick('identity-document')"
                                            >
                                                <rt-icon icon="plus-circle" variant="light" :size="14"/>
                                                {{ i18nT(`Parcourir`) }}
                                            </b-button>

                                            <b-form-file
                                                ref="identity-document"
                                                v-model="identityDocument"
                                                class="hidden"
                                            />

                                        </div >
                                        <small class="mb-1">{{i18nT(`Copie de la pièce d'identité.`)}}{{ i18nT(` Formats acceptés: Word, PDF, Images (GIF, JPG, PNG).`)}}</small>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>

                        <!--                        CONTACT INFO -->
                        <b-row class="border-bottom">
                            <h4 class="mt-2 ml-1 mb-1">{{ i18nT(`Information de contact`) }}</h4>
                        </b-row>
                        <b-row class=" mr-1 ml-1 mb-2">
                            <b-col cols="12" md="12" class="my-2" >
                                <b-row>
                                    <b-col
                                        cols="12"
                                        md="6"
                                    >
                                        <b-form-group
                                            :label="i18nT(`Email`)"
                                        >
                                            <validation-provider
                                                #default="{ errors }"
                                                :name="i18nT(`E-Mail`)"
                                                rules="email"
                                            >
                                                <b-form-input
                                                    id="Email"
                                                    v-model="tenantData.TenantEmail"
                                                    :state="errors.length > 0 ? false:null"
                                                />
                                                <small class="text-danger">{{ errors[0] }}</small>
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col
                                        cols="12"
                                        md="6"
                                    >
                                        <b-form-group
                                            :label="i18nT(`E-mail secondaire`)"
                                        >
                                            <validation-provider
                                                #default="{ errors }"
                                                :name="i18nT(`E-Mail secondaire`)"
                                                rules="email"
                                            >
                                                <b-form-input
                                                    id="Secondary email"
                                                    v-model="tenantData.TenantEmailSecond"
                                                    :state="errors.length > 0 ? false:null"
                                                />
                                                <small class="text-danger">{{ errors[0] }}</small>
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <!--                  TODO - hook up the checkbox with the endpoint-->
                                <b-row>
                                    <b-col
                                        cols="12"
                                        md="6"
                                    >
                                        <b-form-checkbox
                                            name="check-button"
                                            switch
                                            v-model="sendTenantInvite"
                                        >
                                            {{i18nT(`Invitez le locataire à Rentila (un seul e-mail par locataire).`)}}
                                        </b-form-checkbox>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col cols="12" md="6" class="p-0">
                                        <b-alert
                                            class="mt-1 ml-1"
                                            variant="primary"
                                            show
                                        >
                                            <div class="alert-body">
                                            <span>
                                                <b-badge variant="primary">
                                                  {{ i18nT(`Info` ) }}
                                                </b-badge>
                                                <!--                            {{i18nT(`If you want to invite your tenants to Rentila and give them access to the website, please enter their email address (only ONE email per tenant).`)}} <br />-->
                                                <!--                                         {{i18nT(`The tenants will only be able to access information regarding their tenancy, their receipts and they’ll be able to send you messages through the interface.`)}}-->
                                                 {{i18nT(`Le locataire aura accès uniquement aux informations liées à sa location, ses quittances de loyer et il pourra vous envoyer des messages via son interface.`)}}
                                            </span>
                                            </div>
                                        </b-alert>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col
                                        cols="12"
                                        md="6"
                                    >
                                        <b-form-group
                                            :label="i18nT(`Mobile`)"
                                        >
                                            <validation-provider
                                                #default="{ errors }"
                                                :name="i18nT(`Mobile`)"
                                                :rules="{ validPhoneNumber: mazphoneObj.TenantMobilePhone1 }"
                                            >
                                                <MazPhoneNumberInput
                                                    :key="phoneReady"
                                                    id="Phone"
                                                    default-country-code="FR"
                                                    v-model="tenantData.TenantMobilePhone"
                                                    @update="updatePhoneObj($event, 'TenantMobilePhone1')"
                                                    :translations="{
                                                    countrySelectorLabel: i18nT(`Code du pays`),
                                                    countrySelectorError: i18nT(`Choisissez un pays`),
                                                    phoneNumberLabel: i18nT(`Numéro de téléphone`),
                                                    example: i18nT(`Example:`),
                                                  }"
                                                />
                                                <small class="text-danger">{{ i18nT(errors[0]) }}</small>
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col
                                        cols="12"
                                        md="6"
                                    >
                                        <b-form-group
                                            :label="i18nT(`Telephone`)"
                                        >
                                            <validation-provider
                                                #default="{ errors }"
                                                :name="i18nT(`Telephone`)"
                                                :rules="{ validPhoneNumber: mazphoneObj.TenantPhone1 }"
                                            >
                                                <MazPhoneNumberInput
                                                    :key="phoneReady"
                                                    id="Phone"
                                                    default-country-code="FR"
                                                    v-model="tenantData.TenantPhone"
                                                    @update="updatePhoneObj($event, 'TenantPhone1')"
                                                    :translations="{
                                                    countrySelectorLabel: i18nT(`Code du pays`),
                                                    countrySelectorError: i18nT(`Choisissez un pays`),
                                                    phoneNumberLabel: i18nT(`Numéro de téléphone`),
                                                    example: i18nT(`Example:`),
                                                    }"
                                                />
                                                <small class="text-danger">{{ i18nT(errors[0]) }}</small>
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>

                        <!--              ADDRESS-->
                        <b-row class="border-bottom">
                            <h4 class="mt-2 ml-1 mb-1">{{ i18nT(`Adresse`) }}</h4>
                        </b-row>
                        <b-row class="mr-1 ml-1 mb-2 border-bottom">
                            <b-col
                                cols="12"
                                md="12"
                                class="my-2"
                            >
                                <b-row>
                                    <b-col
                                        cols="12"
                                        md="6"
                                    >
                                        <b-form-group
                                            :label="i18nT(`Adresse`)"
                                        >
                                            <b-form-input
                                                v-model="tenantData.TenantAddress1" />
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col
                                        cols="12"
                                        md="6"
                                    >
                                        <b-form-group
                                            :label="i18nT(`Adresse 2`)"
                                        >
                                            <b-form-input
                                                v-model="tenantData.TenantAddress2" />
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col
                                        cols="12"
                                        md="6"
                                    >
                                        <b-form-group
                                            :label="i18nT(`Code postal`)"
                                        >
                                            <b-form-input
                                                v-model="tenantData.TenantZip" />
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col
                                        cols="12"
                                        md="6"
                                    >
                                        <b-form-group
                                            :label="i18nT(`Région`)"
                                        >
                                            <b-form-input
                                                v-model="tenantData.TenantState" />
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col
                                        cols="12"
                                        md="6"
                                    >
                                        <b-form-group
                                            :label="i18nT(`Ville`)"
                                        >
                                            <b-form-input
                                                v-model="tenantData.TenantCity" />
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col
                                        cols="12"
                                        md="6"

                                    >
                                        <b-form-group
                                            :label="i18nT(`Pays`)"
                                        >
                                            <v-select
                                                id="country"
                                                :options="countries"
                                                v-model="tenantData.TenantCountry"
                                                label="text"
                                                :reduce="(e) => e.value"
                                                :clearable="false"
                                            />
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                    </div>
                    <div class="text-right m-1">
                        <b-button
                            variant="primary"
                            @click.prevent="validationForm"
                            class="mb-1"
                            >
                          {{i18nT(`Sauvegarder`)}}
                        </b-button>
                    </div>
                </b-card>
            </b-tab>

                    <!--      GUARANTORS-->

            <b-tab
                 @click="changeTab('guarantors')"
                >
                <template #title>
                    <div class="d-flex align-items-center mb-1 mr-1 ren-title text-truncate">
                        <div class="ren-title-img">
                            <RtIcon :size="21" variant="light" icon="address-card"/>
                        </div>
                        <div>
                        <p class="m-0">
                            <strong>{{ i18nT(`Garants`)}}</strong>
                        </p>
                        <!-- <p class="m-0 text-secondary">
                            {{ i18nT(`Garants du locataire`)}}
                        </p> -->
                        </div>
                    </div>
                </template>
                <b-card>
                    <b-card-header class="p-0">
                        <h2>{{i18nT(`Guarantors`)}}</h2>
                    </b-card-header>
                    <b-row class=" mr-1 ml-1 mb-2 pb-2 border-bottom">
                        <b-col cols="12"
                             md="12"
                             class="pt-1"
                            >
                            <div
                                v-for="(guarantor,index) in guarantors"
                                :key="`guarantor` + index"
                                class="pt-1"
                                >
                                <contact-card :contact="guarantor" :editable="true"  @edit="onEditContact(guarantor,'garant', index)" @delete="onDeleteContact(guarantor, 'garant', index)" />
                            </div>
                            <div class="mb-1 pt-1">
                                <b-button
                                    variant="outline-primary"
                                    @click="openModal('garant')"
                                >
                                    <rt-icon icon="plus-circle" variant="light" :size="14"/>
                                  {{i18nT(`Ajouter un garant`)}}
                                </b-button>
                            </div>
                            <small>{{i18nT(`Vous pouvez ajouter plusieurs garants si besoin. Ce contact sera sauvegardé dans la rubrique carnet.`)}}</small>
                        </b-col>
                    </b-row>
                    <div class="text-right m-1">
                      <b-button
                          variant="primary"
                          @click.prevent="validationForm"
                          class="mb-1"
                      >
                        {{i18nT(`Sauvegarder`)}}
                      </b-button>
                    </div>
                </b-card>
            </b-tab>

                    <!--      EMERGENCY CONTACT-->

            <b-tab
                :title="i18nT(`Contacts d'urgence`)"
                @click="changeTab('emergency-contacts')"
                >
                <template #title>
                    <div class="d-flex align-items-center mb-1 mr-1 ren-title text-truncate">
                        <div class="ren-title-img">
                            <RtIcon :size="21" variant="light" icon="address-card"/>
                        </div>
                        <div>
                        <p class="m-0">
                            <strong>{{ i18nT(`Contacts d'urgence`)}}</strong>
                        </p>
                        <!-- <p class="m-0 text-secondary">
                            {{ i18nT(`Contacts d'urgence du locataire`)}}
                        </p> -->
                        </div>
                    </div>
                </template>
                <b-card>
                    <b-card-header class="p-0">
                        <h2>{{i18nT(`Contacts d'urgence`)}}</h2>
                    </b-card-header>
                    <b-row class=" mr-1 ml-1 mb-2 pb-2 border-bottom">
                        <b-col cols="12"
                               md="12"
                               class="pt-1"
                            >
                            <div
                                v-for="(contact,index) in emergencyContacts"
                                :key="`emergencyContact` + index"
                                class="pt-1"
                                >
                                    <contact-card :contact="contact" :editable="true" @edit="onEditContact(contact,'contact', index)" @delete="onDeleteContact(contact, 'contact', index)" />
                            </div>
                            <div class="pt-1 mb-1">
                                <b-button
                                    variant="outline-primary"
                                    @click="openModal('contact')"
                                >
                                    <rt-icon icon="plus-circle" variant="light" :size="14"/>
                                  {{i18nT(`Ajouter un contact`)}}
                                </b-button>

                            </div>
                            <small>{{i18nT(`Vous pouvez ajouter plusieurs contacts si besoin. Chaque contact sera sauvegardé dans la rubrique carnet.`)}}</small>
                        </b-col>
                    </b-row>
                    <div class="text-right m-1">
                        <b-button
                            variant="primary"
                            @click.prevent="validationForm"
                            class="mb-1"
                        >
                          {{i18nT(`Sauvegarder`)}}
                        </b-button>
                    </div>
                </b-card>

            </b-tab>

                  <!--          DOCUMENTS-->

            <b-tab
                :title="i18nT(`Documents`)"
                @click="changeTab('documents')"
                >
                <template #title>
                    <div class="d-flex align-items-center mb-1 mr-1 ren-title">
                        <div class="ren-title-img">
                            <RtIcon :size="21" variant="light" icon="briefcase"/>
                        </div>
                        <div>
                        <p class="m-0">
                            <strong>{{ i18nT(`Documents`)}}</strong>
                        </p>
                        <!-- <p class="m-0 text-secondary">
                            {{ i18nT(`Documents liés au locataire`)}}
                        </p> -->
                        </div>
                    </div>
                </template>
                <b-card>
                    <b-card-header class="p-0">
                        <h2>{{i18nT(`Documents`)}}</h2>
                    </b-card-header>
                    <b-row class=" mr-1 ml-1 mb-2 pb-2 border-bottom">
                        <b-col cols="12"
                               md="12"
                               class="pt-1"
                        >
                            <div
                                v-for="(item,index) in documents"
                                :key="`document` + index"
                                class="pb-1 pt-1"
                                >
                                <document-card  :document="item" :editable="item['_can_edit']" @edit="onEditDocument(item, index)" @delete="onDeleteDocument(item,index)" />
                            </div>
                            <div class="pt-1 mb-1">
                                <b-button
                                    variant="outline-primary"
                                   @click="openModal('document')"
                                    >
                                    <rt-icon icon="plus-circle" variant="light" :size="14"/>
                                  {{i18nT(`Ajouter un document`)}}
                                </b-button>
                            </div>
                            <small>{{i18nT(`Vous pouvez ajouter plusieurs documents. Ces documents seront sauvegardés dans la rubrique Documents.`)}}</small>
                        </b-col>
                    </b-row>
                    <div class="text-right m-1">
                        <b-button
                            variant="primary"
                            @click.prevent="validationForm"
                            class="mb-1"
                        >
                          {{i18nT(`Sauvegarder`)}}
                        </b-button>
                    </div>
                </b-card>
            </b-tab>

        </b-tabs>
  </validation-observer>
  
    <!--                MODAL ADD DOCUMENT-->

    <modal-add-document :documents="existingDocuments" :document-temp-id="documentTempId" location="tenants" @add="addDocument"/>

    <!--  MODAL ADD CONTACT-->

    <modal-add-contact :contact="currentContact" :type="modalType" @add="addContact"/>

    <!--                  MODAL EDIT DOCUMENT-->

    <modal-edit-document :document="tenantDocument" />
  </div>
</template>
<script>

import {
    BTab,
    BTabs,
    BCard,
    BRow,
    BCol,
    BCollapse,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormCheckboxGroup,
    BDropdownItem,
    BDropdown,

} from 'bootstrap-vue'

import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import AvatarControl from "@/views/components/avatar/AvatarControl.vue";
import {ValidationProvider, ValidationObserver, extend} from 'vee-validate'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { required, email} from '@validations'
import DatePicker from "@core/components/DatePicker.vue";
import ModalAddContact from "@/views/landlord/Tenants/ModalAddContact.vue";
import moment from "moment";
import router from "@/router";
import ContactCard from "@/views/components/contactCard/ContactCard.vue";
import ModalAddDocument from "@/views/common/Documents/ModalAddDocument.vue";
import DocumentCard from "@/views/common/Documents/DocumentCard.vue";
import ModalEditDocument from "@/views/common/Documents/ModalEditDocument.vue";
import { MazBtn,MazPhoneNumberInput } from 'maz-ui'
// import MazPhoneNumberInput from "maz-ui/lib/maz-phone-number-input";
import EllipsisDropdown from '@core/components/EllipsisDropdown.vue'



    extend('validPhoneNumber', {
      params: ['phoneNumberData'],
      validate: (value, maz) => {
        if(maz.phoneNumberData){
          return maz.phoneNumberData.isValid ;
        }else {
          return false;
        }
      },
      message: `Invalid phone number`,
    });

    extend('validMultiPhoneNumber', {
      params: ['phoneNumberData'],
      validate: (value, maz) => {
        if(maz.phoneNumberData) {
          return maz.phoneNumberData.isValid;
        } else {
          return false;
        }
      },
      message: `Invalid phone number`,
    });

    extend('date_format', {
      validate(value) {
        return {
          required: true,
          valid: moment(value, this.dateFormat).isValid() && value.length === 10 && moment(value).isBetween('1900-01-01', '2999-12-31')
        };
      },
      message: `Invalid date.`,
    })

    export default {
    components: {
          BDropdown,
          BDropdownItem,
          ModalEditDocument,
          DocumentCard,
          ModalAddDocument,
          ContactCard,
          ModalAddContact,
          AvatarControl,
          BFormGroup,
          BFormInput,
          BTab,
          BTabs,
          BCard,
          BRow,
          BCollapse,
          BCol,
          vSelect,
          EllipsisDropdown,
          ValidationProvider,
          ValidationObserver,
          DatePicker,
          BFormCheckbox,
          BFormCheckboxGroup,
          MazBtn,
          MazPhoneNumberInput,

    },
      directives: {
        Ripple,
      },
    data() {
          return {
                tenantData: {
                    TenantFirstName: '',
                    TenantLastName: '',
                },
                sendTenantInvite : false,
                mazphoneObj: {
                    TenantMobilePhone: {},
                    TenantMobilePhone1: {},
                    TenantPhone: {},
                    TenantPhone1: {},
                    TenantProPhone: {},
                    TenantProPhone1: {}
                },
                mazPhones: [],
                phoneReady:'no',
                countries: [],
                tenantType: '',
                tenantTypes: [],
                guarantors: [],
                availableGuarantor: null,
                emergencyContacts: [],
                availableEmergencyContact: null,
                tenantColor: '',
                tenantDefaultColor: '',
                titleOptions: [],
                tenantTitle: '',
                identityType: {value: '', text: ''},
                identityOptions: [],
                required,
                email,
                identityDocument: null,
                identityDocumentName: '',
                identityDocumentURL: '',
                identityDocumentUploadDate: null,
                tenantDocument: null,
                tenantDocumentName: '',
                tenantDocumentURL: '',
                documents: [],
                existingDocument:false,
                existingDocuments: [],
                documentTypes: [],
                sendToTenant: false,
                tabNames: ['general-info', 'additional-info', 'guarantors', 'emergency-contacts', 'documents'],
                tabIndex: 0,
                currentContact: null,
                modalType: 'garant',
                tenantImage: null,
                tenantImageUrl: null,
                dateFormat: 'YYYY-MM-DD',
                currentTenantId: router.currentRoute.params.id,
                curIndex: null,
                curDocumentIndex: null,
                documentTempId: null,
            }
    },
    created() {

        let tab = this.$route.params.tab
        if(tab){
        this.tabIndex = this.tabNames.indexOf(tab)
        }

        this.$http.get('landlord/tenants/new').then(({data}) => {

            this.documentTempId = data.data.DocumentTempID;

            this.countries = data.data.countries.map(country => {
            return {
                value: country.id,
                text: country.label,
            }
            })

            this.identityOptions = data.data.identity_types.map (identity => {
            return {
                value: identity.id,
                text: identity.label
            }
            })

            this.tenantTypes = data.data.user_types.map(type=>{
                return {
                    value: type.id,
                    text: type.label
                }
            })

            this.existingDocuments = data.data['all_documents']
        }).catch((err) => {
            this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                    title: this.errorMessage(err),
                    icon: 'CoffeeIcon',
                    variant: 'danger',
                    text: this.i18nT(`Please try again later.`)
                }
            })
        })

        this.$http.get(`system/constants/titles`).then(({ data }) => {
            this.titleOptions= data.data.map(title=>{
                return {
                    value: title.id,
                    text: title.label
                }
            })
        });


        if(this.currentTenantId){
            this.$http.get(`landlord/tenants?id=${router.currentRoute.params.id}&edit_data=1`)
            .then(({data}) => {
                this.tenantData = data.data.tenant;
                this.emergencyContacts = data.data.contacts;
                this.guarantors = data.data.garants;
                this.documents = data.data.documents;
                this.tenantData.TenantBirthDate = data.data.tenant.TenantBirthDate ? moment(data.data.tenant.TenantBirthDate).format('YYYY-MM-DD') : '';  //TODO: change when you fix useAuth.getDateFormat
                this.tenantColor = data.data.tenant.TenantAvatarHexColor ? `#${data.data.tenant.TenantAvatarHexColor}`: '';
                this.tenantData.TenantCreateTime = data.data.tenant.TenantCreateTime ? moment(data.data.tenant.TenantCreateTime).format('DD/MM/YYYY') : '';
                this.tenantData.TenantLastEditDate = data.data.tenant.TenantLastEditDate ? moment(data.data.tenant.TenantLastEditDate).format('DD/MM/YYYY') : '';
                this.tenantType = data.data.tenant.TenantType;
                this.tenantTitle = data.data.tenant.TenantTitle;
                this.phoneReady = 'yes';
                const identityType = this.identityOptions.find(type => type.value === data.data.tenant.TenantIDType);
                if(identityType) {
                    this.identityType = identityType;
                }
                if(data.data.tenant['id_card_date']){
                    this.identityDocumentUploadDate = moment(data.data.tenant['id_card_date']).format('DD/MM/YYYY');
                }
                if(data.data.tenant['id_card_download_url']) {
                    this.identityDocumentURL = data.data.tenant['id_card_download_url'];
                }

            }).catch((err) => {
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: this.errorMessage(err),
                        icon: 'CoffeeIcon',
                        variant: 'danger',
                    }
                })

                console.log(err)
            })
        }
      },
    methods: {
            changeType(type){
                this.tenantType = type;
            },
          addContact(contact){
            if(this.modalType === 'garant'){
                if(this.curIndex != null){
                    this.guarantors[this.curIndex] = {...contact};
                    this.guarantors=[...this.guarantors];
                    this.curIndex =  null;
                    return;
                }
              const checkExisting = this.guarantors.find(guarantor => guarantor.ContactID === contact.ContactID);
              if(checkExisting){
                  const index = this.guarantors.indexOf(checkExisting);
                  this.guarantors[index] = {...contact};
                  this.guarantors=[...this.guarantors];
                  return;
              }
                  this.guarantors.push(contact);
                  this.curIndex = null;

            } else {
                if(this.curIndex != null){
                    this.emergencyContacts[this.curIndex] = {...contact};
                    this.emergencyContacts=[...this.emergencyContacts];
                    this.curIndex =  null;
                    return;
                }
                const checkExisting = this.emergencyContacts.find(emergencyContact => emergencyContact.ContactID === contact.ContactID);
                if(checkExisting){
                    const index = this.emergencyContacts.indexOf(checkExisting);
                    this.emergencyContacts[index] = {...contact};
                    this.emergencyContacts=[...this.emergencyContacts];
                    return;
                }
              this.emergencyContacts.push(contact);
              this.curIndex = null;
            }
          },
          onEditContact(contact, type, index){

            this.currentContact = {...contact};
            this.modalType = type;
            this.curIndex = index;
            this.$bvModal.show('modal-add-contact')
          },
          onDeleteContact(contact, type, index){
          this.$swal({
              title: this.i18nT(`Avertissement`),
              text: this.i18nT(`Veuillez confirmer la suppression.?`),
              icon: 'warning',
              showCancelButton: true,
              confirmButtonText: this.i18nT(`Confirmer`),
              cancelButtonText: this.i18nT(`Annuler`),
              customClass: {
                  confirmButton: 'btn btn-primary',
                  cancelButton: 'btn btn-outline-danger ml-1'
              },
              buttonsStyling: false
          }).then(result => {
              if(result.value) {
                  this.$http.delete(`landlord/tenants/${type}?tenant_id=${this.currentTenantId}&${type}_id=${contact.ContactID}`).then(()=>{
                      this.$swal({
                          icon: 'success',
                          title: this.i18nT(`Succès!`),
                          text: this.i18nT(`Supprimé avec succès!`),
                          customClass: {
                              confirmButton: 'btn btn-success'
                          }
                      })
                      if(type === 'garant'){

                          this.guarantors.splice(index, 1);
                          this.guarantors = [...this.guarantors];
                          this.curIndex=null;
                      } else {
                          this.emergencyContacts.splice(index, 1);
                          this.emergencyContacts = [...this.emergencyContacts];
                          this.curIndex=null;
                      }
                  })
              }
          }).catch(err => {
              this.$swal({
                  icon: 'error',
                  title: 'Error!',
                  text: this.errorMessage(err),
                  customClass: {
                      confirmButton: 'btn btn-success'
                  }
              })
          })
          },
          onEditDocument(document, index){
            this.tenantDocument = document;
            this.curDocumentIndex = index;
            this.$bvModal.show('modal-edit-document')
          },
          addDocument(newDocument){
              if(this.curDocumentIndex != null){
                  this.documents[this.curDocumentIndex] = {...newDocument};
                  this.documents=[...this.documents];
                  this.curDocumentIndex =  null;
                  return;
              }
              const checkExisting = this.documents.find(document => document.DocumentID === newDocument.DocumentID);
              if(checkExisting){
                  const index = this.documents.indexOf(checkExisting);
                  this.documents[index] = {...newDocument};
                  this.documents=[...this.documents];
                  return;
              }
              this.documents.push(newDocument);
              this.curDocumentIndex = null;
          },
          onDeleteDocument(document, index){
              this.$swal({
                  title: this.i18nT(`Avertissement`),
                  text: this.i18nT(`Veuillez confirmer la suppression.?`),
                  icon: 'warning',
                  showCancelButton: true,
                  confirmButtonText: this.i18nT(`Confirmer`),
                  cancelButtonText: this.i18nT(`Annuler`),
                  customClass: {
                      confirmButton: 'btn btn-primary',
                      cancelButton: 'btn btn-outline-danger ml-1'
                  },
                  buttonsStyling: false
              }).then(result => {
                  if(result.value) {

                        const id = this.currentTenantId ? `TenantID=${this.currentTenantId}` : `DocumentTempID=${this.documentTempId}`;

                        if (!this.currentTenantId && !document['_new_upload']) {
                            this.documents.splice(index, 1);
                            this.documents = [...this.documents];
                            this.curDocumentIndex=null;
                            return
                        }

                        this.$http.delete(`landlord/tenants/document?${id}&DocumentID=${document.DocumentID}`).then(()=>{
                            this.$swal({
                                icon: 'success',
                                title: this.i18nT(`Succès!`),
                                text: this.i18nT(`Supprimé avec succès!`),
                                customClass: {
                                    confirmButton: 'btn btn-success'
                                }
                            })

                            this.documents.splice(index, 1);
                            this.documents = [...this.documents];
                            this.curDocumentIndex=null;
                        })
                  }
              }).catch(err => {
                  this.$swal({
                      icon: 'error',
                      title: 'Error!',
                      text: this.errorMessage(err),
                      customClass: {
                          confirmButton: 'btn btn-success'
                      }
                  })
              })
          },
          openModal(type){
            this.currentContact = null;
            this.curIndex = null;

            if(type=='document'){
                this.curDocumentIndex = null;
                this.tenantDocument = null;
                this.$bvModal.show('modal-add-document');
                return;
            }
            if(type=='contact'){
              this.modalType = type;
            } else {
              this.modalType = 'garant';
            }
            this.$bvModal.show('modal-add-contact')
          },
          changeTab(tab){
            this.tabIndex = this.tabNames.indexOf(tab)

            let currentPath

            if(!this.$route.params.id){
                currentPath='/landlord/tenants/new'
            } else {
                currentPath=`/landlord/tenants/${this.$route.params.id}/edit`
            }

            history.pushState({}, null,  currentPath+ "/" + encodeURIComponent(tab));
          },
          onSetNewAvatar(newData) {
            this.tenantImage = newData.newUserImage;
            this.tenantImageUrl = newData.newImageUrl;
            // this.tenantData = newData.newProfileData;
          },
          updatePhoneObj(dataCollected, key){
            this.mazphoneObj[key] = dataCollected;
          },
          onFilePick: function (type) {
            if (type == 'identity-document') {
              this.$refs['identity-document'].$refs.input.click()
            }
          },
          onFileDelete: function (type) {
            if (type == 'identity-document') {
              this.identityDocument = null
              this.identityDocumentName = ''
              this.identityDocumentURL= '';
               console.log('url', this.identityDocumentURL)
            }
          },
          onDelete() {
            this.$swal({
                title: this.i18nT(`Avertissement`),
                text: this.i18nT(`Veuillez confirmer la suppression du locataire.`),
                icon: 'warning',
                showCancelButton: true,
                cancelButtonText: this.i18nT(`Annuler`),
                confirmButtonText: this.i18nT(`Confirmer`),
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1'
                },
                buttonsStyling: false
            }).then(result => {
                if (result.value) {
                    this.$http
                        .delete(`landlord/tenants?id=${this.currentTenantId}`)
                        .then(() => {
                            this.$swal({
                                icon: 'success',
                                title: this.i18nT(`Succès!`),
                                text: this.i18nT(`Supprimé avec succès!`),
                                customClass: {
                                    confirmButton: 'btn btn-success'
                                }
                            })
                            this.$router.push({ name: 'tenants' })
                        })
                }
            }).catch(err => {
                this.$swal({
                    icon: 'error',
                    title: 'Error!',
                    text: this.errorMessage(err),
                    customClass: {
                        confirmButton: 'btn btn-success'
                    }
                })
            })
        },
          validationForm() {
            this.$refs.simpleRules.validateWithInfo().then(({isValid, errors, fields}) => {
              if (isValid) {
                const formData  = new FormData();
                if(router.currentRoute.params.id) {
                  formData.append('id', router.currentRoute.params.id);
                } else {
                    formData.append('DocumentTempID', this.documentTempId);
                }
                // GENERAL INFO

                formData.append('TenantTitle', this.tenantTitle);
                formData.append('TenantFirstName', this.tenantData.TenantFirstName);
                formData.append('TenantMiddleName', this.tenantData.TenantMiddleName);
                formData.append('TenantLastName', this.tenantData.TenantLastName);
                formData.append('TenantBirthDate', this.tenantData.TenantBirthDate ? this.tenantData.TenantBirthDate : null);
                formData.append('TenantPlaceOfBirth', this.tenantData.TenantPlaceOfBirth);
                formData.append('TenantFiscalCode', this.tenantData.TenantFiscalCode);
                formData.append('TenantAvatarHexColor', this.tenantColor.slice(1));
                if(this.tenantType) {
                  formData.append('TenantType', this.tenantType);
                }

                //TENANT PHOTO

                if(this.tenantImage) {
                    console.log(this.tenantImage)
                  formData.append('TenantPhoto', this.tenantImage);
                }


                // JOB STATUS

                formData.append('TenantProfession', this.tenantData.TenantProfession);
                formData.append('TenantRevenus', this.tenantData.TenantRevenus); //TODO check the correct property

                // PROOF OF IDENTITY

                if(this.identityType.value != '-1') {
                    formData.append('TenantIDType', this.identityType.value);
                }
                formData.append('TenantIDNumber', this.tenantData.TenantIDNumber);
                formData.append('TenantIDExpiry', this.tenantData.TenantIDExpiry ? this.tenantData.TenantIDExpiry : null);
                if(this.identityDocument){
                    formData.append('TenantIDCard', this.identityDocument);
                }



                // CONTACT INFO

                formData.append('TenantEmail', this.tenantData.TenantEmail);
                formData.append('TenantEmailSecond', this.tenantData.TenantEmailSecond);
                formData.append('TenantMobilePhone', this.tenantData.TenantMobilePhone);
                formData.append('TenantPhone', this.tenantData.TenantPhone);

                // ADDRESS

                formData.append('TenantAddress1', this.tenantData.TenantAddress1);
                formData.append('TenantAddress2', this.tenantData.TenantAddress2);
                formData.append('TenantCity', this.tenantData.TenantCity);
                formData.append('TenantZip', this.tenantData.TenantZip);
                formData.append('TenantState', this.tenantData.TenantState);
                formData.append('TenantCountry', this.tenantData.TenantCountry);

                // COMPANY INFO

                formData.append('TenantCompanyName', this.tenantData.TenantCompanyName);
                formData.append('TenantVatNumber', this.tenantData.TenantVatNumber);
                formData.append('TenantSiret', this.tenantData.TenantSiret);
                formData.append('TenantCompanyDescription', this.tenantData.TenantCompanyDescription);
                formData.append('TenantCapital', this.tenantData.TenantCapital);

                // TENANT INVITE

                  if(this.sendTenantInvite){
                      formData.append('send_invite', 1)
                  }



                // PROFESSIONAL ADDRESS

                formData.append('TenantProEmployer', this.tenantData.TenantProEmployer);
                formData.append('TenantProAddress', this.tenantData.TenantProAddress);
                formData.append('TenantProZip', this.tenantData.TenantProZip);
                formData.append('TenantProState', this.tenantData.TenantProState);
                formData.append('TenantProCity', this.tenantData.TenantProCity);
                formData.append('TenantProCountry', this.tenantData.TenantProCountry);
                formData.append('TenantProPhone', this.tenantData.TenantProPhone);

                // BANK DETAILS

                formData.append('TenantBankName', this.tenantData.TenantBankName);
                formData.append('TenantBankIBAN', this.tenantData.TenantBankIBAN);
                formData.append('TenantBankSwiftBic', this.tenantData.TenantBankSwiftBic);
                formData.append('TenantBankAddress', this.tenantData.TenantBankAddress);
                formData.append('TenantBankZip', this.tenantData.TenantBankZip);
                formData.append('TenantBankState', this.tenantData.TenantBankState);
                formData.append('TenantBankCity', this.tenantData.TenantBankCity);
                formData.append('TenantBankCountry', this.tenantData.TenantBankCountry);

                // ADDITIONAL INFO

                formData.append('TenantLeaveAddress', this.tenantData.TenantLeaveAddress);
                formData.append('TenantNotes', this.tenantData.TenantNotes);


                // GARANTS
                  if(this.guarantors.length>0){
                      const garantIDs = this.guarantors.map(guarant=> guarant.ContactID).join(',');
                      formData.append('GarantID', garantIDs);
                  }


                 // EMERGENCY CONTACTS
                  if(this.emergencyContacts.length>0){
                      const emergencyContactIDs=this.emergencyContacts.map(contact=> contact.ContactID).join(',');
                      formData.append('ContactID', emergencyContactIDs);
                  }

                  //DOCUMENTS
                  if(this.documents.length>0){
                      const documentIDs = this.documents.map(document=> document.DocumentID).join(',');
                      formData.append('DocumentID', documentIDs);
                  }




                this.$http.post(`landlord/tenants`, formData).then(({ data }) => {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: this.successMessage(data),
                      icon: 'InfoIcon',
                      variant: 'success'
                    }
                  })
                  this.$router.push({ name: 'tenants' })
                }).catch(ex => {
                      this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                          title: this.errorMessage(ex),
                          icon: 'InfoIcon',
                          variant: 'danger'
                        }
                      })
                    })
              }  else {
                let y = 1000000
                let prevY = 1000000
                for (const key in errors) {
                  if (errors[key].length > 0) {
                    this.$toast({
                      component: ToastificationContent,
                      position: 'top-right',
                      props: {
                        title: errors[key][0],
                        icon: 'AlertTriangleIcon',
                        variant: 'danger'
                      }
                    })
                    // console.log(fields[key])
                    const el = document.getElementById(fields[key].id)
                    prevY =
                        el.getBoundingClientRect().top + window.scrollY - 150
                    if (prevY <= y) y = prevY
                  }
                }
                if (y < 1000000) {
                  window.scrollTo({ top: y, behavior: 'smooth' })
                }
              }

            })
          },
    },
    watch: {
          identityDocument: function (val) {
              if (val) {
                console.log("val: ", val)
                this.identityDocument = val
                this.identityDocumentName = val.Label ? val.Label : (val.name ? val.name : null)
                this.identityDocumentURL = URL.createObjectURL(val)
              } else {
                this.identityDocument= null
                this.identityDocumentName = ''
                this.identityDocumentURL = ''

              }
          },
          tenantImage: function(val) {
              if(val){
                  this.tenantImageUrl = URL.createObjectURL(val)
              } else {
                  this.tenantImageUrl = ''
              }
          }
    },

  }
  </script>

  <style lang="scss">

  @import 'maz-ui/lib/css/maz-phone-number-input';

  @media(min-width: 1500px){
      .ren-radio-type-buttons{
          max-width: 50%;
      }
  }

  .ren-identity-photo {
    max-width: 150px;
    max-height: 150px;
  }

  .nav-item > a {
    color: inherit;
  }

  .nav-item > a:hover {
    color: inherit;
  }

  .ren-action-dropdown {
    margin-top: -20px;
    text-align: right;
  }

  .ren-action-dropdown .dropdown {
    margin-top: -50px;
  }

  .ren-action-btn {
    padding: 2px;
  }


  </style>