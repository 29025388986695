<template>
  <b-row>
    <b-col cols="12">
      <div class="d-flex" :class="noBottomMargin ? '' : 'mb-2'">
        <b-avatar
            :src="localImageUrl"
            :text="getAvatarText(currentUser)"
            class="mr-1 badge-primary "
            :style="{backgroundColor:currentUser.hexColor ? '#'+ currentUser.hexColor : '#a3b1c1'}"
            size="6rem"
        >
        </b-avatar>
        <div class="">
          <h2>{{ currentUser.fullName}}</h2>
          <b-form-file
              v-model="imageFileObject"
              class="hidden"
              plain
              ref="filePicker"
              accept="image/*"
              @change="onFileChange"
          />
          <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              class="my-1 mr-1"
              @click="onFilePick"

          >
              <rt-icon icon="circle-plus" variant="light" />
            {{  localImageUrl ? i18nT(`Modifier une photo`) : i18nT(`Ajouter une photo`)}}
          </b-button>
          <span v-if="file">
                      {{fileName}}
              <span><rt-icon icon="trash" variant="light" :size="16" /></span>
                    </span>

          <b-button
              v-if="localImageUrl"
              variant="outline-danger"
              class="mt-0"
              @click="onDeleteAvatar()"
          >
            <rt-icon icon="circle-minus" variant="light" />
            {{i18nT(`Supprimer`)}}
          </b-button>
        </div>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BFormFile,
  BAvatar,
  VBModal,
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive'
import router from "@/router";
import ToastificationContent from "@core/components/toastification/ToastificationContent";

export default {
  name: "AvatarControl",
  components: {
    BRow,
    BCol,
    BButton,
    BFormFile,
    BAvatar
  },
  directives: {
    Ripple,
    'b-modal': VBModal
  },
  props: {
    profileData: {
      type: Object,
      default: () => {
        return {
          FirstName: '',
          LastName: '',
        };
      }
    },
    UserImage: {
      default: null
    },
    parentId: {
      type: String | Number,
      default: null
    },
    noBottomMargin: {
      type: Boolean,
      default: false
    },
    userType:{
        type: String,
        default: ''
    }
  },
  data() {
    return {
      file: null,
      currentUser: {},
      UserImageUrl: null,
      computedImage: null,
      localImageUrl: null,
      imageFileObject: null,
    }
  },
  methods: {
    getAvatarText(currentUser) {
      if(currentUser.firstName && currentUser.lastName) {
        return `${currentUser.firstName.charAt(0)}${currentUser.lastName.charAt(0)}`
      } else if(currentUser.companyName){
        return `${currentUser.companyName.charAt(0)}${currentUser.companyName.charAt(1)}`
      } else{
          return '';
      }
    },
    onFilePick: function() {
      this.$refs['filePicker'].$refs.input.click()
    },
    onFileChange(event){
      this.$emit('setNewAvatar', {
        newUserImage: event.target.files[0],
        newImageUrl: this.UserImageUrl,
        newProfileData: this.profileData
      });
    },

    // TODO NEEDS ENGLISH TRANSALATION

    onDeleteAvatar() {
      this.$swal({
        title: this.i18nT(`Are you sure you want to delete this image?`),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.i18nT(`Confirm`),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
            if (this.profileData['image_url'] && this.currentUser.id) {
                this.$http
                    .delete(`landlord/tenants/photo?id=` + this.currentUser.id)
                    .then((data) => {
                        this.profileData.ImageUrl = null
                        this.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                                title: this.successMessage(data),
                                icon: 'InfoIcon',
                                variant: 'success'
                            }
                        })
                        this.localImageUrl = null;
                        this.imageFileObject = null;
                        this.$emit('setNewAvatar', {
                            newUserImage: '',
                            newImageUrl: '',
                            newProfileData: this.profileData
                        });

                    })
                    .catch(ex => {
                        console.log('catch')
                        console.log(ex)
                        this.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                                title: `Error occured`,
                                iconF: 'InfoIcon',
                                variant: 'danger'
                            }
                        })
                    })
            } else {
                this.localImageUrl = null;
                this.imageFileObject = null;
                this.$emit('setNewAvatar', {
                    newUserImage: '',
                    newImageUrl: '',
                    newProfileData: this.profileData
                });
            }
        }
      })
    }
  },
  watch: {
    file: function(val) {
      if (val) {
        this.file = val
        this.fileName = val.name
      } else {
        this.file = null
        this.fileName = ''
      }
    },
    imageFileObject: function(val) {
      if(val) {
        this.localImageUrl = URL.createObjectURL(val)
      }
    },
    profileData: {
        handler: function(val){
            if(this.userType=='tenant'){
                this.currentUser.firstName = val.TenantFirstName;
                this.currentUser.lastName = val.TenantLastName;
                this.currentUser.fullName = val.TenantFullName;
                this.currentUser.hexColor = val.TenantAvatarHexColor? val.TenantAvatarHexColor : val.TenantDefaultHexColor;
                this.currentUser.compnayNname = val.CompanyName;
                this.currentUser.id = val.TenantID;
                this.localImageUrl = val['image_url'];
            } else if (this.userType=='landlord'){
                this.currentUser.firstName = val.LandlordFirstName;
                this.currentUser.lastName = val.LandlordLastName;
                this.currentUser.fullName = val.LandlordFullName;
                this.currentUser.companyName = val.LandlordCompayName;
                this.currentUser.id = val.LandlordID;
                this.localImageUrl = val['image_url'];
            } else if(this.userType=='contact'){
                this.currentUser.firstName= val.ContactFirstName;
                this.currentUser.lastName = val.ContactLastName;
                this.currentUser.companyName = val.ContactCompanyName;
                this.currentUser.id = val.ContactID;
                this.currentUser.hexColor = val.ContactAvatarHexColor ? val.ContactAvatarHexColor : val.ContactDefaultHexColor;
                this.localImageUrl = val['image_url'];
            }
        },
        deep: true
    }
  }
}
</script>

<style scoped>

</style>
