<template>
    <b-modal
      id="modal-add-document"
      :title="type ? i18nT(`Ajouter un nouveau`) : i18nT(`Nouveau document`)"
      ok-only
      :ok-title="i18nT(`Sauvegarder`)"
      no-close-on-backdrop
      @ok="saveNewDocument"
      @hidden="resetForm"
      size="lg"
      @shown="getDocumentTypes"
    >
    <validation-observer ref="simpleRules">
        <b-card no-body>
            <b-row class=" mr-1 ml-1 mb-2 pb-2">
                <b-col cols="12"
                     md="12"
                     class="pt-2"
                >
                    <b-row>
                        <b-col
                          cols="12"
                          md="12"
                        >
                            <b-form-checkbox
                              name="check-button"
                              ref="existingDocument"
                              switch
                              v-model="chooseDocument"
                              @change="resetForm"
                            >
                          {{ i18nT(`Choisir un document existant`) }}
                        </b-form-checkbox>
                        </b-col>
                    </b-row>
                    <div v-if="!chooseDocument" class="mt-1">
                        <b-row>
                            <b-col cols="12" md="8">
                              <div class='pb-1'>
                                  <!--                      TODO add methods for file upload-->
                                  <b-button
                                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                      class="my-50 mr-1 mb-0"
                                      variant="outline-primary"
                                      @click="onFilePick('new-document')"
                                  >
                                      <rt-icon icon="circle-plus" size="16" variant="light"/>
                                      {{ i18nT(`Parcourir`) }}
                                  </b-button>
                                    <validation-provider
                                        :name="i18nT(`File`)"
                                        #default="{ errors }"
                                        rules="required"
                                    >
                                        <b-form-file
                                            ref="new-document"
                                            v-model="newDocument"
                                            class="hidden"
                                        />
                                        <small class="text-danger">{{ i18nT(errors[0]) }}</small>
                                    </validation-provider>
                                  <span class="pb-0 mb-0" v-if="newDocument || newDocumentName">
                              <b-link v-if="newDocumentURL" :href="newDocumentURL">
                                  {{ newDocumentName }}
                              </b-link>
                              <span v-else>
                                  {{ newDocumentName }}
                              </span>
                              <span class="ml-50 text-danger cursor-pointer" @click="onFileDelete"><rt-icon icon="trash" variant="light" :size="16"/></span>
                          </span>
                                  <br/>
                                  <small class="mb-1">
                                      {{ i18nT(`Formats acceptés: Word, Excel, PDF, Images (GIF, JPG, PNG)`) }}<br/>
                                      {{ i18nT(`Taille maximale: 15MB.`) }}
                                  </small>
                              </div>
                          </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="12"
                                 md="6"
                            >
                                <b-form-group
                                    :label="i18nT(`Type`)"
                                    class="required"
                                >
                                    <validation-provider
                                        :name="i18nT(`Type`)"
                                        #default="{ errors }"
                                        rules="required"
                                    >
                                        <v-select
                                            v-model="documentType"
                                            :options="documentTypes"
                                            label="text"
                                            :clearable="false"
                                            :reduce="(e) => e.value"
                                            :placeholder="i18nT(`Choisir`)"
                                        />
                                        <small class="text-danger">{{ i18nT(errors[0]) }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="12"
                                 md="12"
                            >
                                <b-form-group
                                  :label="i18nT(`Description`)"
                                >
                                    <b-form-textarea
                                          id='Private note'
                                          v-model="newDocumentDescription"
                                          :placeholder = "type ? i18nT(`Description, type, société, durée, autres informations ...`) : '' "
                                    />
                                    </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row v-if="!type">
                          <b-col
                              cols="12"
                              md="6"
                          >
                              <b-form-checkbox
                                  name="check-button"
                                  switch
                                  v-model="sendToTenant"
                              >
                                  {{ i18nT(`Partager le document avec votre locataire`) }}
                              </b-form-checkbox>
                          </b-col>
                        </b-row>
                        <b-row v-if="type">
                            <b-col cols="12" md="6">
                                <b-form-group :label="i18nT(`Date d'établissement`)">
                                    <date-picker
                                        v-model="tenancyContractDate"
                                        :manual-input="true"
                                        reset-button
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" md="6">
                                <b-form-group :label="i18nT(`Date d'échéance`)">
                                    <date-picker
                                        v-model="tenancyContractDateTo"
                                        :manual-input="true"
                                        reset-button
                                    />
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </div>
                    <div v-else class="mt-1">
                        <b-row>
                            <b-col cols="12" md="12">
                            <b-form-group
                              :label="i18nT(`Fishier`)"
                            >
                                <validation-provider
                                    :name="i18nT(`Fishier`)"
                                    #default="{ errors }"
                                    rules="required"
                                >
                                    <v-select
                                        :id="i18nT(`Fishier`)"
                                        v-model="existingDocument"
                                        :options="existingDocuments"
                                        label="text"
                                        :clearable="false"
                                        :reduce="(e) => e.value"
                                        :class="{ 'border-invalid': errors.length > 0 }"
                                    />
                                    <small class="text-danger">{{ i18nT(errors[0]) }}</small>
                                </validation-provider>
                            </b-form-group>
                            </b-col>
                        </b-row>
                    </div>
              </b-col>
          </b-row>
        </b-card>
    </validation-observer>
    </b-modal>
</template>

<script>

import {
  BCard,
  BCol,
  BFormCheckbox,
  BFormGroup,
  BModal,
  BRow,
} from 'bootstrap-vue'
import vSelect from "vue-select";
import Ripple from 'vue-ripple-directive'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import { required } from '@validations'
import moment from 'moment';
import DatePicker from "@core/components/DatePicker.vue";
import router from "@/router";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {

    components: {
        BRow,
        BCard,
        BFormCheckbox,
        BCol,
        BFormGroup,
        vSelect,
        BModal,
        ValidationProvider,
        ValidationObserver,
        DatePicker
    },
    directives: {
        Ripple
    },
    props: {
      documents: {
        type: Array,
        default: []
      },
      type: {
        type: String,
        default: ''
      },
      location: {
        type: String,
        default: ''
      },
      documentTempId: {
        type: String,
        default: ''
      }
    },
    data() {
        return {
        tenancyContractDate: '',
        tenancyContractDateTo: '',
        newDocument: null,
        newDocumentDescription: '',
        newDocumentName: '',
        newDocumentURL: '',
        chooseDocument: false,
        existingDocument: {
          value: '-1',
          text: this.i18nT('Choisir')
        },
        existingDocuments: [],
        documentTypes: [],
        documentType: null,
        sendToTenant: false,
        isDataFetched: false,
        // validations
        required,
        }
    },
    created() {


      // TODO - add method for getting existing documents

    },
    methods: {
        getDocumentTypes(){
            if(this.isDataFetched) return;

            if(!this.type){
                this.$http.get(`system/constants/documentTypes`).then(({data}) => {
                    this.documentTypes = data.data.map(document=>{
                        return {
                            value: document.label.DocumentTypeID,
                            text: document.label.DocumentType
                        }
                    })
                    this.isDataFetched = true;
                })
            } else if(this.type==='insurance') {
                //TODO needs to get date from back-end
                this.documentTypes = [
                    {
                    value: '1',
                    text: this.i18nT(`Assurance locataire`)
                    },
                    {
                    value: '2',
                    text: this.i18nT(`Assurance loyer impayé`),
                    }
                ]
                this.isDataFetched = true;
            }
        },
        onFilePick(type) {
          if (type && type == 'new-document') {
            this.$refs['new-document'].$refs.input.click()
          }
        },
        resetForm(){
          this.newDocument = null;
          this.newDocumentName = '';
          this.newDocumentURL = '';
          this.existingDocument = {
              value: '-1',
              text: this.i18nT('Choisir')
          };
          this.sendToTenant = false;
          this.newDocumentDescription = '';
          this.documentType = null
        },
        
        saveNewDocument(event){
            event.preventDefault()
                this.$refs.simpleRules.validateWithInfo().then(({isValid, errors, fields}) => {
                    if(isValid) {
                        const formData = new FormData();
                        if(router.currentRoute.params.id) {
                            const id = router.currentRoute.params.id

                            //add cases when implemented in properties, buildings, leases etc
                            switch (this.location) {
                                case 'tenants':
                                    formData.append('TenantID', id);
                                    break;
                                case 'properties':
                                    formData.append('PropertyID', id);
                                    break;
                                case 'buildings':
                                    formData.append('BuildingID', id);
                                    break;
                            }
                        }else{
                            formData.append('DocumentTempID', this.documentTempId);
                        }

                        if(this.chooseDocument) {
                            formData.append('DocumentID', this.existingDocument)
                        } else {
                            formData.append('DocumentFile', this.newDocument);
                            formData.append('DocumentName', this.newDocumentName);
                            formData.append('DocumentDescription', this.newDocumentDescription);
                            formData.append('DocumentTypeID', this.documentType);
                            formData.append('DocumentIsShared', this.sendToTenant ? 1 : 0);
                        }

                        this.$http.post(`landlord/${this.location}/document`, formData).then(({data}) => {
                            this.$emit('add', data.data); // could be changed to refetchData later
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.successMessage(data),
                                    icon: 'InfoIcon',
                                    variant: 'success'
                                }
                            })
                            this.$bvModal.hide('modal-add-document');
                            this.resetForm();
                        }).catch(ex => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.errorMessage(ex),
                                    icon: 'InfoIcon',
                                    variant: 'danger'
                                }
                            })
                        })

                    // let reader = new FileReader();
                    // reader.readAsDataURL(this.newDocument)
                    //
                    // reader.onloadend = () => {
                    //     // Get the Base64 string
                    //     const document = {}
                    //     document.base64String = reader.result.split(',')[1];
                    //     document.DocumentName = this.newDocumentName
                    //     document.DocumentTypeID = this.documentType
                    //     document.DocumentDescription = this.newDocumentDescription
                    //     document.DocumentIsShared = this.sendToTenant ? "1" : "0"
                    //     document.DocumentCreateTime = moment().format('YYYY-MM-DD HH:mm:ss')
                    //     document['_can_edit'] = true
                    //
                    //     this.$emit('add', document)
                    //     this.$bvModal.hide('modal-add-document')
                    // }
                }
            })

        },
        onFileDelete() {
            this.newDocument = null;
            this.newDocumentName = '';
            this.newDocumentURL = '';
        }

    },
    watch: {
        documents: {
            handler: function (val) {
                this.existingDocuments = val.map(document=>{
                  return {
                    value: document.id,
                    text: document.label
                  }
                })
                this.existingDocuments.unshift({
                  value: '-1',
                  text: this.i18nT('Choisir')
                });
            },
            deep: true
        },
      newDocument: function(val) {
            if (val) {
              console.log(val)
              this.newDocumentName = val.name
              this.newDocument = val
              this.newDocumentURL = URL.createObjectURL(val)
            } else{
              this.newDocumentName = ''
              this.newDocument = null
              this.newDocumentURL = ''
          }
        }
    }
}

</script>