<template>
    <b-card no-body border-variant="primary" class="mb-0">
        <b-card-body class="pt-75 pb-75">
            <b-row
                >
                <b-col
                    cols="12"
                    md="12"
                    lg="1"
                    class="text-center mt-1 mb-1"
                    sm="12"
                >
                  <rt-icon :icon="icon" variant="thin" :size="40" />
                </b-col>
                <b-col
                    md="12"
                    lg="10"
                    cols="12"
                    class="pl-2"
                    sm="12"
                >
                    <h5 v-b-tooltip.hover="i18nT(currentDocument.DocumentName)" class="cursor-pointer text-truncate w-75 5 mb-0 mt-75">
                        <b-link :href="currentDocument['download_url']">{{ currentDocument.DocumentName }}</b-link></h5>
                    <small>{{ currentDocument.DocumentCreateTime | date }}</small>
                    <p
                        class="text-truncate m-0"
                    >
                      {{ currentDocument.DocumentDescription? currentDocument.DocumentDescription : i18nT(`Pas de description`) }}
                    </p>
                </b-col>
                <b-col
                    cols="12"
                    md="12"
                    sm="12"
                    lg="1"
                >
                    <b-badge
                        v-b-tooltip = "getTooltipLabel(currentDocument.DocumentIsShared, currentDocument['_can_edit'])"
                        :variant="statusLabelClass(currentDocument.DocumentIsShared, currentDocument['_can_edit'])"
                        class="pill float-right mb-1 ml-1"
                    >
                      <p class="m-0"> {{statusLabel(currentDocument.DocumentIsShared, currentDocument['_can_edit'])}}</p>
                    </b-badge>
                    <div v-if="editable" class="float-right">
                        <b-button-group
                            size="sm"
                            >
                            <b-button
                                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                variant="outline-primary"
                                @click="onEdit"
                                >
                                <rt-icon icon="edit" variant="light" :size="16"/>
                            </b-button>
                            <b-button
                                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                variant="outline-primary"
                                @click="onDelete"
                            >
                                <rt-icon icon="trash" variant="light" :size="16"/>
                            </b-button>
                        </b-button-group>
                    </div>
                </b-col>
            </b-row>
        </b-card-body>
  </b-card>
</template>

<script>



import {
  BAvatar,
  VBTooltip,
  BCard,
  BCardBody,
  BLink,
  BCol,
  BRow,
  BBadge,
} from "bootstrap-vue";
import  Ripple  from "vue-ripple-directive";


export default  {
    components: {
      BAvatar,
      BCard,
      BRow,
      BCol,
      BBadge,
      BCardBody,
      BLink
    },
    directives: {
        Ripple,
        'b-tooltip': VBTooltip

    },
    props: {
      document: {
        type: Object,
        default: null,
      },
      editable: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
            currentDocument: {},
            formatDic: [
              {type: 'pdf', icon: 'file-pdf'},
              {type: 'doc', icon: 'file-doc'},
              {type: 'docx', icon: 'file-doc'},
              {type: 'word', icon: 'file-word'},
              {type: 'openoffice', icon: 'file-lines'},
              {type: 'jpg', icon: 'file-jpg'},
              {type: 'png', icon: 'file-png'},
              {type: 'xls', icon: 'file-xls'},
              {type: 'xlsx', icon: 'file-xls'},
            ],
            icon: 'file-text',
      }
    },
    methods: {
        onEdit() {
          this.$emit("edit", this.currentDocument);
        },
        onDelete(){
            this.$emit("delete", this.currentDocument);
        },
        statusLabelClass(isShared, canEdit) {
            if (isShared==1) {
               if (canEdit==1) {
                 return "primary";
               }
              return "info";
            } else {
              return "light-secondary";
            }
        },
        statusLabel(isShared, canEdit) {
            if (isShared==1) {
               if (canEdit==1) {
                 return this.i18nT("Partagé");
               }
              return this.i18nT("Partagé");
            } else {
              return this.i18nT("Privé");
            }
        },
        getTooltipLabel(isShared, canEdit) {
            if (isShared==1) {
               if (canEdit==1) {
                 return this.i18nT(`Document partagé avec le(s) locataire(s)`);
               }
                return this.i18nT(`Document partagé par le(s) locataire(s)`);
            } else {
                return this.i18nT(`Le document n'est pas partagé`);
            }
        },
    },
    watch: {
        document: {
          handler: function (val) {
            this.currentDocument = val;
            const findIcon = this.formatDic.find((format) => format.type === val.DocumentType);
            if(findIcon){
                this.icon = findIcon.icon;
            }
          },
          immediate: true,
        },
    }
}
</script>

