<template>
    <b-card no-body border-variant="primary" class="mb-1">
        <b-card-body class="p-1">
            <div
                class="d-lg-flex align-items-center"
            >
                <div class="d-flex justify-content-center align-content-center">
                    <b-avatar
                        :src="contact['image_thumb_url']"
                        :text="(contact.ContactFirstName ? contact.ContactFirstName.charAt(0) : '') + (contact.ContactLastName ? contact.ContactLastName.charAt(0) : '')"
                        class="badge-light-danger mr-1 cursor-pointer"
                        size="4rem"
                        :style="{
                            backgroundColor: '#' + (contact.ContactAvatarHexColor ? contact.ContactAvatarHexColor: contact.ContactDefaultHexColor),
                            boxShadow: '0 0 0 2px white, 0 0 0 4px #' + (contact.ContactAvatarHexColor ? contact.ContactAvatarHexColor: contact.ContactDefaultHexColor),
                            color:'white',
                            }"
                        :to="{path: '/'}"
                    >
                    </b-avatar>
                </div>
                <div class="flex-grow-1 ml-50">
                    <h3 class="cursor-pointer mb-0">{{ contact.ContactFirstName }} {{contact.ContactLastName}}</h3>
                    <small>{{ contactType }}</small>
                    <div class="info-item mt-50 text-truncate">

                        <rt-icon icon="phone" variant="light" :size="16"/>

                        <b-link
                            v-if="contact.ContactMobilePhone"
                            :href="'tel:'+contact.ContactMobilePhone"
                            class="position-relative pl-1"
                        >{{ contact.ContactMobilePhone}}</b-link>

                        <span v-else class="pl-1">{{i18nT(`Not available`)}}</span>
                    </div>
                    <div class="info-item text-truncate">

                        <rt-icon icon="envelope" variant="light" :size="16"/>

                        <b-link
                            v-if="contact.ContactEmail"
                            :href="'mailto:'+contact.ContactEmail"
                            class="position-relative pl-1"
                        >{{ contact.ContactEmail}}</b-link>

                        <span v-else class="pl-1">{{i18nT(`Not available`)}}</span>
                    </div>
                </div>
                <div v-if="editable" class="float-right">
                    <b-button-group
                        size="sm"
                        class=" float-right"
                        >
                        <b-button
                            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                            variant="outline-primary"
                            @click="onEdit"

                        >
                          <rt-icon icon="edit" variant="light" :size="16"/>
                        </b-button>
                        <b-button
                            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                            variant="outline-primary"
                            @click="onDelete"
                        >
                            <rt-icon icon="trash" variant="light" :size="16"/>
                        </b-button>
                    </b-button-group>
              </div>
            </div>
        </b-card-body>
  </b-card>
</template>


<script>

import {
  BAvatar,
  BCard,
  BCardBody,
  BLink
} from "bootstrap-vue";
import Ripple from 'vue-ripple-directive'

export default {
    components: {
      BCard,
      BCardBody,
      BAvatar,
      BLink
    },
    directives: {
        Ripple
    },
    props: {
        contact: {
            type: Object,
            default: null
        },
        editable: {
          type: Boolean,
          default: false
        }
    },
    data(){
      return {
        contactType: this.contact.ContactType
                    ? this.contact.ContactType === 'person' ? 'Individual' : 'Company'
                    : ''
      }
    },
    methods: {
        onEdit() {
            this.$emit('edit');
        },
        onDelete(){
            this.$emit('delete');
        }
    }

}

</script>

<style>

</style>